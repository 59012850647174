import {
  chaineVersMasqueEuro,
  chaineVersMasqueAnnees,
} from "components/form/transform";
import { IConclusion } from "types";
import QuestionMarkHelper from "components/QuestionMarkHelper";

interface TableauTresorerieProps {
  conclusion: IConclusion;
}

export default function TableauTresorerie({
  conclusion,
}: TableauTresorerieProps) {
  return (
    <table className="pf-c-table pf-m-grid-md pf-m-compact">
      <thead>
        <tr>
          <th>Trésorerie</th>
          <th className="text-right">Mensuel</th>
          <th className="text-right">Annuel</th>
        </tr>
      </thead>
      <tbody>
        <tr
          className={`${
            conclusion.bilan.annuel > 0 ? "bg-green-100" : "bg-red-100"
          }`}
        >
          <td>Bilan de tréso</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.bilan.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.bilan.annuel)}
          </td>
        </tr>
        <tr className="bg-blue-100">
          <td>Revenus</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.revenus.loyers.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.revenus.loyers.annuel)}
          </td>
        </tr>
        <tr className="bg-blue-50">
          <td>Loyers</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.revenus.loyers.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.revenus.loyers.annuel)}
          </td>
        </tr>
        <tr className="bg-red-100">
          <td>Charges</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.total_charges.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.total_charges.annuel)}
          </td>
        </tr>
        <tr className="bg-red-50">
          <td>
            Mensualité Crédit
            <span className="ml-2">
              <QuestionMarkHelper
                content={
                  <div>
                    Ce montant représente la moyenne mensuel du crédit pendant
                    la durée de projet étudié
                    {conclusion.amortissement.duree_calcul === 0 ? null : (
                      <span>{` (${chaineVersMasqueAnnees(
                        conclusion.amortissement.duree_calcul
                      )})`}</span>
                    )}
                  </div>
                }
              />
            </span>
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.mensualite_credit.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.mensualite_credit.annuel)}
          </td>
        </tr>
        <tr className="bg-red-50">
          <td>Frais de gestion</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.frais_de_gestion.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.frais_de_gestion.annuel)}
          </td>
        </tr>
        <tr className="bg-red-50">
          <td>GLI</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.GLI.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.GLI.annuel)}
          </td>
        </tr>
        <tr className="bg-red-50">
          <td>Taxe foncière</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.taxe_fonciere.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.taxe_fonciere.annuel)}
          </td>
        </tr>
        <tr className="bg-red-50">
          <td>Assurance PNO</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.assurance_PNO.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.assurance_PNO.annuel)}
          </td>
        </tr>
        <tr className="bg-red-50">
          <td>Charges de copropriété</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(
              conclusion.charges.charges_copropriete.mensuel
            )}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(
              conclusion.charges.charges_copropriete.annuel
            )}
          </td>
        </tr>
        <tr className="bg-red-50">
          <td>Frais comptables</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.frais_comptable.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.frais_comptable.annuel)}
          </td>
        </tr>
        <tr className="bg-red-50">
          <td>CFE</td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.CFE.mensuel)}
          </td>
          <td className="text-right font-mono">
            {chaineVersMasqueEuro(conclusion.charges.CFE.annuel)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
