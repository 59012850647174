import React from "react";
import { Button } from "@patternfly/react-core";
import { Input } from "components/form";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { ISignUpForm } from "types";

const SignUpSchema = Yup.object().shape({
  prenom: Yup.string().required("Votre nom est obligatoire"),
  nom: Yup.string().required("Votre prénom est obligatoire"),
  email: Yup.string()
    .email("Veuillez rentrez un email valide")
    .required("L'email est obligatoire"),
  password: Yup.string().required("Le mot de passe est obligatoire"),
});

interface SignUpFormProps {
  onSubmit: (credentials: ISignUpForm) => Promise<void>;
}

export default function SignUpForm({ onSubmit }: SignUpFormProps) {
  return (
    <Formik
      initialValues={{ prenom: "", nom: "", email: "", password: "" }}
      validationSchema={SignUpSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        onSubmit(values).then(() => actions.setSubmitting(false));
      }}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form id="signup_form" className="pf-c-form">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Input
              id="prenom"
              data-testid="prenom"
              label="Prénom"
              name="prenom"
              type="text"
              isRequired
            />
            <Input
              id="nom"
              data-testid="nom"
              label="Nom"
              name="nom"
              type="text"
              isRequired
            />
          </div>
          <Input
            id="email"
            data-testid="email"
            label="Email"
            name="email"
            type="email"
            isRequired
          />
          <Input
            id="password"
            data-testid="password"
            label="Password"
            name="password"
            type="password"
            isRequired
          />
          <Button
            variant="primary"
            type="submit"
            isDisabled={!(isValid && dirty && !isSubmitting)}
          >
            {isSubmitting ? "Création de votre compte" : "Créer un compte"}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
