import React from "react";
import { Button } from "@patternfly/react-core";
import { Input } from "components/form";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { ICredentials } from "types";

const CreateUserSchema = Yup.object().shape({
  email: Yup.string().email("Rentrez un email valide").required("obligatoire"),
  password: Yup.string().required("obligatoire"),
});

interface CreateUserFormProps {
  onSubmit: (credentials: ICredentials) => void;
}

export default function CreateUserForm({ onSubmit }: CreateUserFormProps) {
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={CreateUserSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty }) => (
        <Form id="login_form" className="pf-c-form">
          <Input
            id="email"
            data-testid="email"
            label="Email"
            name="email"
            type="email"
            isRequired
          />
          <Input
            id="password"
            data-testid="password"
            label="Password"
            name="password"
            type="password"
            isRequired
          />
          <Button
            variant="primary"
            type="submit"
            isDisabled={!(isValid && dirty)}
          >
            Se connecter
          </Button>
        </Form>
      )}
    </Formik>
  );
}
