import {
  IVariante,
  LigneAmortissement,
  ILigneTableauAnnualiseFoncier,
  IDonneesFiscales,
  ILigneTableauAnnualiseBICLMNP,
  ILigneTableauAnnualiseIS,
  ILigneTableauAnnualiseBICLMP,
  IDonneeFiscale,
} from "types";
import { get, round } from "lodash";
import { calcul_impot } from "./impots";
import { addMonths, parse, getYear, getMonth } from "date-fns";

function _arrondirEnNegatif(valeur: number) {
  return valeur === 0 ? 0 : -round(valeur, 2);
}

function recupererInfoFiscale(
  annee: string | number,
  donnees_fiscales_annulalisees: IDonneeFiscaleAnnualise
): IDonneeFiscale {
  const annee_nombre = typeof annee === "number" ? annee : parseInt(annee, 10);
  if (annee_nombre in donnees_fiscales_annulalisees) {
    return donnees_fiscales_annulalisees[annee_nombre];
  }
  return { annee: annee_nombre, revenu_net_imposable: 0, quotient_familial: 1 };
}

interface TotalAnnee {
  nb_mois: number;
  loyers: number;
  capital_restant: number;
  credit: number;
  interets: number;
  assurance_pret: number;
  frais_de_gestion: number;
  GLI: number;
  taxe_fonciere: number;
  assurance_PNO: number;
}

interface TotalParAn {
  [key: string]: TotalAnnee;
}

function _calcul_foncier(
  total_par_an: TotalParAn,
  montant_travaux: number,
  donnees_fiscales_annulalisees: IDonneeFiscaleAnnualise
) {
  const revenuFonciers: ILigneTableauAnnualiseFoncier[] = [];
  let cumul_cashflow = 0;
  let report_annee_precendente = 0;
  for (let i = 0; i < Object.keys(total_par_an).length; i++) {
    const annee = Object.keys(total_par_an)[i];
    const total_annee = total_par_an[annee];
    const estLaPremiereAnnee = i === 0;
    const charges_ponctuelles = estLaPremiereAnnee ? montant_travaux : 0;
    const charges_recurrentes =
      total_annee.frais_de_gestion +
      total_annee.GLI +
      total_annee.taxe_fonciere +
      total_annee.assurance_PNO +
      total_annee.assurance_pret +
      total_annee.interets;
    const charges_specifiques = 0;
    const amortissement = 0;
    const loyers = total_annee.loyers;
    const cashflow_brut =
      loyers -
      charges_recurrentes -
      charges_specifiques -
      total_annee.credit +
      total_annee.assurance_pret +
      total_annee.interets;
    const base_imposable =
      report_annee_precendente +
      loyers -
      charges_ponctuelles -
      charges_recurrentes -
      charges_specifiques -
      amortissement;
    const { revenu_net_imposable, quotient_familial } = recupererInfoFiscale(
      annee,
      donnees_fiscales_annulalisees
    );
    const revenu_fiscal_reference =
      base_imposable < 0
        ? revenu_net_imposable
        : revenu_net_imposable + base_imposable;
    const deficit_foncier = 10_700;
    const revenu_fiscal_reference_premiere_annee =
      base_imposable < deficit_foncier
        ? revenu_net_imposable - deficit_foncier
        : revenu_net_imposable + base_imposable;
    const RFR = estLaPremiereAnnee
      ? revenu_fiscal_reference_premiere_annee
      : revenu_fiscal_reference;
    const impots = calcul_impot(RFR, quotient_familial);
    const pourcentage_prelevement_sociaux = 17.2;
    const prelevement_sociaux =
      base_imposable < 0
        ? 0
        : (base_imposable * pourcentage_prelevement_sociaux) / 100;
    const difference_impots =
      impots +
      prelevement_sociaux -
      calcul_impot(revenu_net_imposable, quotient_familial);
    const impot_total_investissement =
      difference_impots < 0 ? 0 : difference_impots;
    const cashflow_net = cashflow_brut - impot_total_investissement;
    const report_annee_precendente_arrondi = round(report_annee_precendente, 2);
    const loyers_arrondi = round(loyers, 2);
    const charges_ponctuelles_arrondi = _arrondirEnNegatif(charges_ponctuelles);
    const charges_recurrentes_arrondi = _arrondirEnNegatif(charges_recurrentes);
    const charges_specifiques_arrondi = _arrondirEnNegatif(charges_specifiques);
    const amortissement_arrondi = _arrondirEnNegatif(amortissement);
    const credit_arrondi = _arrondirEnNegatif(total_annee.credit);
    const cashflow_brut_arrondi = round(cashflow_brut, 2);
    const base_imposable_arrondi = round(base_imposable, 2);
    const revenu_fiscal_reference_arrondi = round(RFR, 2);
    const prelevement_sociaux_arrondi = round(prelevement_sociaux, 2);
    const impot_total_investissement_arrondi = round(
      impot_total_investissement,
      2
    );
    const cashflow_net_arrondi = round(cashflow_net, 2);
    const rente_par_mois_arrondi = round(cashflow_net / 12, 2);
    cumul_cashflow += cashflow_net;
    const cumul_cashflow_arrondi = round(cumul_cashflow, 2);
    const capital_restant_arrondi = round(total_annee.capital_restant, 2);
    revenuFonciers.push({
      annee: annee,
      report_annee_precendente: report_annee_precendente_arrondi,
      loyers: loyers_arrondi,
      capital_restant: capital_restant_arrondi,
      charges_ponctuelles: charges_ponctuelles_arrondi,
      charges_recurrentes: charges_recurrentes_arrondi,
      charges_specifiques: charges_specifiques_arrondi,
      amortissement: amortissement_arrondi,
      credit: credit_arrondi,
      cashflow_brut: cashflow_brut_arrondi,
      base_imposable: base_imposable_arrondi,
      revenu_net_imposable,
      revenu_fiscal_reference: revenu_fiscal_reference_arrondi,
      impots,
      prelevement_sociaux: prelevement_sociaux_arrondi,
      impot_total_investissement: impot_total_investissement_arrondi,
      cashflow_net: cashflow_net_arrondi,
      rente_par_mois: rente_par_mois_arrondi,
      cumul_cashflow: cumul_cashflow_arrondi,
    });
    if (base_imposable < 0) {
      report_annee_precendente = base_imposable;
    } else {
      report_annee_precendente = 0;
    }
  }
  return revenuFonciers;
}

function _calcul_BIC_LMNP(
  total_par_an: TotalParAn,
  montant_travaux: number,
  variante: IVariante,
  donnees_fiscales_annulalisees: IDonneeFiscaleAnnualise
) {
  const revenuBIC: ILigneTableauAnnualiseBICLMNP[] = [];
  let cumul_cashflow = 0;
  let report_annee_precendente = 0;
  let total_amortissement = 0;
  let max_total_amortissement = 0.8 * variante.prix_achat_FAI;
  for (let i = 0; i < Object.keys(total_par_an).length; i++) {
    const annee = Object.keys(total_par_an)[i];
    const total_annee = total_par_an[annee];
    const estLaPremiereAnnee = i === 0;

    const charges_ponctuelles = estLaPremiereAnnee
      ? montant_travaux +
        variante.frais_de_notaire +
        variante.frais_de_garanties
      : 0;
    const charges_recurrentes =
      total_annee.frais_de_gestion +
      total_annee.GLI +
      total_annee.taxe_fonciere +
      total_annee.assurance_PNO +
      total_annee.assurance_pret +
      total_annee.interets;
    const charges_specifiques =
      ((variante.CFE + variante.frais_comptable) * total_annee.nb_mois) / 12;
    const pourcentage_amortissement = 2;
    let amortissement =
      (((variante.prix_achat_FAI * pourcentage_amortissement) / 100) *
        total_annee.nb_mois) /
      12;
    if (total_amortissement + amortissement > max_total_amortissement) {
      amortissement = max_total_amortissement - total_amortissement;
    }
    total_amortissement += amortissement;
    const loyers = total_annee.loyers;
    const cashflow_brut =
      loyers -
      charges_recurrentes -
      charges_specifiques -
      total_annee.credit +
      total_annee.assurance_pret +
      total_annee.interets;
    const base_imposable =
      report_annee_precendente +
      loyers -
      charges_ponctuelles -
      charges_recurrentes -
      charges_specifiques -
      amortissement;
    const { revenu_net_imposable, quotient_familial } = recupererInfoFiscale(
      annee,
      donnees_fiscales_annulalisees
    );
    const revenu_fiscal_reference =
      base_imposable < 0
        ? revenu_net_imposable
        : revenu_net_imposable + base_imposable;
    const RFR = revenu_fiscal_reference;
    const impots = calcul_impot(RFR, quotient_familial);
    const pourcentage_prelevement_sociaux = 17.2;
    const prelevement_sociaux =
      base_imposable < 0
        ? 0
        : (base_imposable * pourcentage_prelevement_sociaux) / 100;
    const difference_impots =
      impots +
      prelevement_sociaux -
      calcul_impot(revenu_net_imposable, quotient_familial);
    const impot_total_investissement =
      difference_impots < 0 ? 0 : difference_impots;
    const cashflow_net = cashflow_brut - impot_total_investissement;
    const report_annee_precendente_arrondi = round(report_annee_precendente, 2);
    const loyers_arrondi = round(loyers, 2);
    const charges_ponctuelles_arrondi = _arrondirEnNegatif(charges_ponctuelles);
    const charges_recurrentes_arrondi = _arrondirEnNegatif(charges_recurrentes);
    const charges_specifiques_arrondi = _arrondirEnNegatif(charges_specifiques);
    const amortissement_arrondi = _arrondirEnNegatif(amortissement);
    const credit_arrondi = _arrondirEnNegatif(total_annee.credit);
    const cashflow_brut_arrondi = round(cashflow_brut, 2);
    const base_imposable_arrondi = round(base_imposable, 2);
    const revenu_fiscal_reference_arrondi = round(RFR, 2);
    const prelevement_sociaux_arrondi = round(prelevement_sociaux, 2);
    const impot_total_investissement_arrondi = round(
      impot_total_investissement,
      2
    );
    const cashflow_net_arrondi = round(cashflow_net, 2);
    const rente_par_mois_arrondi = round(cashflow_net / 12, 2);
    cumul_cashflow += cashflow_net;
    const cumul_cashflow_arrondi = round(cumul_cashflow, 2);
    const capital_restant_arrondi = round(total_annee.capital_restant, 2);
    revenuBIC.push({
      annee: annee,
      report_annee_precendente: report_annee_precendente_arrondi,
      loyers: loyers_arrondi,
      capital_restant: capital_restant_arrondi,
      charges_ponctuelles: charges_ponctuelles_arrondi,
      charges_recurrentes: charges_recurrentes_arrondi,
      charges_specifiques: charges_specifiques_arrondi,
      amortissement: amortissement_arrondi,
      credit: credit_arrondi,
      cashflow_brut: cashflow_brut_arrondi,
      base_imposable: base_imposable_arrondi,
      revenu_net_imposable,
      revenu_fiscal_reference: revenu_fiscal_reference_arrondi,
      impots,
      prelevement_sociaux: prelevement_sociaux_arrondi,
      impot_total_investissement: impot_total_investissement_arrondi,
      cashflow_net: cashflow_net_arrondi,
      rente_par_mois: rente_par_mois_arrondi,
      cumul_cashflow: cumul_cashflow_arrondi,
    });
    if (base_imposable < 0) {
      report_annee_precendente = base_imposable;
    } else {
      report_annee_precendente = 0;
    }
  }
  return revenuBIC;
}

function _calcul_BIC_LMP(
  total_par_an: TotalParAn,
  montant_travaux: number,
  variante: IVariante,
  donnees_fiscales_annulalisees: IDonneeFiscaleAnnualise
) {
  const revenuBIC: ILigneTableauAnnualiseBICLMP[] = [];
  let cumul_cashflow = 0;
  let base_calcul_prix_session = variante.prix_achat_FAI;
  let report_annee_precendente = 0;
  let total_amortissement = 0;
  let max_total_amortissement = 0.8 * variante.prix_achat_FAI;
  for (let i = 0; i < Object.keys(total_par_an).length; i++) {
    const annee = Object.keys(total_par_an)[i];
    const total_annee = total_par_an[annee];
    const estLaPremiereAnnee = i === 0;

    const charges_ponctuelles = estLaPremiereAnnee
      ? montant_travaux +
        variante.frais_de_notaire +
        variante.frais_de_garanties
      : 0;
    const charges_recurrentes =
      total_annee.frais_de_gestion +
      total_annee.GLI +
      total_annee.taxe_fonciere +
      total_annee.assurance_PNO +
      total_annee.assurance_pret +
      total_annee.interets;
    const charges_specifiques =
      ((variante.CFE + variante.frais_comptable) * total_annee.nb_mois) / 12;
    const pourcentage_amortissement = 2;
    let amortissement =
      (((variante.prix_achat_FAI * pourcentage_amortissement) / 100) *
        total_annee.nb_mois) /
      12;
    if (total_amortissement + amortissement > max_total_amortissement) {
      amortissement = max_total_amortissement - total_amortissement;
    }
    total_amortissement += amortissement;
    const loyers = total_annee.loyers;
    const cashflow_brut =
      loyers -
      charges_recurrentes -
      charges_specifiques -
      total_annee.credit +
      total_annee.assurance_pret +
      total_annee.interets;
    const base_imposable =
      report_annee_precendente +
      loyers -
      charges_ponctuelles -
      charges_recurrentes -
      charges_specifiques -
      amortissement;
    const { revenu_net_imposable, quotient_familial } = recupererInfoFiscale(
      annee,
      donnees_fiscales_annulalisees
    );
    const revenu_fiscal_reference =
      base_imposable < 0
        ? revenu_net_imposable
        : revenu_net_imposable + base_imposable;
    const RFR = revenu_fiscal_reference;
    const impots = calcul_impot(RFR, quotient_familial);
    const pourcentage_cotisations_sociales = 30;
    const minimal_cotisations_sociales = estLaPremiereAnnee ? 0 : 1042;
    const cotisations_sociales =
      base_imposable < 0
        ? minimal_cotisations_sociales
        : Math.max(
            minimal_cotisations_sociales,
            (base_imposable * pourcentage_cotisations_sociales) / 100
          );
    const difference_impots =
      impots +
      cotisations_sociales -
      calcul_impot(revenu_net_imposable, quotient_familial);
    const impot_total_investissement =
      difference_impots < 0 ? 0 : difference_impots;
    const cashflow_net = cashflow_brut - impot_total_investissement;
    const report_annee_precendente_arrondi = round(report_annee_precendente, 2);
    const loyers_arrondi = round(loyers, 2);
    const charges_ponctuelles_arrondi = _arrondirEnNegatif(charges_ponctuelles);
    const charges_recurrentes_arrondi = _arrondirEnNegatif(charges_recurrentes);
    const charges_specifiques_arrondi = _arrondirEnNegatif(charges_specifiques);
    const amortissement_arrondi = _arrondirEnNegatif(amortissement);
    const credit_arrondi = _arrondirEnNegatif(total_annee.credit);
    const cashflow_brut_arrondi = round(cashflow_brut, 2);
    const base_imposable_arrondi = round(base_imposable, 2);
    const revenu_fiscal_reference_arrondi = round(RFR, 2);
    const cotisations_sociales_arrondi = round(cotisations_sociales, 2);
    const impot_total_investissement_arrondi = round(
      impot_total_investissement,
      2
    );
    const cashflow_net_arrondi = round(cashflow_net, 2);
    const rente_par_mois_arrondi = round(cashflow_net / 12, 2);
    cumul_cashflow += cashflow_net;
    const cumul_cashflow_arrondi = round(cumul_cashflow, 2);
    base_calcul_prix_session -= amortissement;
    const base_calcul_prix_session_arrondi = round(base_calcul_prix_session, 2);
    const capital_restant_arrondi = round(total_annee.capital_restant, 2);
    revenuBIC.push({
      annee: annee,
      report_annee_precendente: report_annee_precendente_arrondi,
      loyers: loyers_arrondi,
      capital_restant: capital_restant_arrondi,
      charges_ponctuelles: charges_ponctuelles_arrondi,
      charges_recurrentes: charges_recurrentes_arrondi,
      charges_specifiques: charges_specifiques_arrondi,
      amortissement: amortissement_arrondi,
      credit: credit_arrondi,
      cashflow_brut: cashflow_brut_arrondi,
      base_imposable: base_imposable_arrondi,
      revenu_net_imposable,
      revenu_fiscal_reference: revenu_fiscal_reference_arrondi,
      impots,
      cotisations_sociales: cotisations_sociales_arrondi,
      impot_total_investissement: impot_total_investissement_arrondi,
      cashflow_net: cashflow_net_arrondi,
      rente_par_mois: rente_par_mois_arrondi,
      cumul_cashflow: cumul_cashflow_arrondi,
      base_calcul_prix_session: base_calcul_prix_session_arrondi,
    });
    if (base_imposable < 0) {
      report_annee_precendente = base_imposable;
    } else {
      report_annee_precendente = 0;
    }
  }
  return revenuBIC;
}

function _calcul_IS(
  total_par_an: TotalParAn,
  montant_travaux: number,
  variante: IVariante
) {
  const revenuIS: ILigneTableauAnnualiseIS[] = [];
  let cumul_cashflow = 0;
  let base_calcul_prix_session = variante.prix_achat_FAI;
  let report_annee_precendente = 0;
  let disponibilite_n_moins_1 = 0;
  let total_amortissement = 0;
  let max_total_amortissement = 0.8 * variante.prix_achat_FAI;
  for (let i = 0; i < Object.keys(total_par_an).length; i++) {
    const annee = Object.keys(total_par_an)[i];
    const total_annee = total_par_an[annee];

    const estLaPremiereAnnee = i === 0;

    const charges_ponctuelles = estLaPremiereAnnee
      ? montant_travaux +
        variante.frais_de_notaire +
        variante.frais_de_garanties
      : 0;
    const charges_recurrentes =
      total_annee.frais_de_gestion +
      total_annee.GLI +
      total_annee.taxe_fonciere +
      total_annee.assurance_PNO +
      total_annee.assurance_pret +
      total_annee.interets;
    const charges_specifiques =
      ((variante.CFE + variante.frais_comptable) * total_annee.nb_mois) / 12;
    const pourcentage_amortissement = 2;
    let amortissement =
      (((variante.prix_achat_FAI * pourcentage_amortissement) / 100) *
        total_annee.nb_mois) /
      12;
    if (total_amortissement + amortissement > max_total_amortissement) {
      amortissement = max_total_amortissement - total_amortissement;
    }
    total_amortissement += amortissement;
    const loyers = total_annee.loyers;
    const cashflow_brut =
      loyers -
      charges_recurrentes -
      charges_specifiques -
      total_annee.credit +
      total_annee.assurance_pret +
      total_annee.interets;
    const base_imposable =
      report_annee_precendente +
      loyers -
      charges_ponctuelles -
      charges_recurrentes -
      charges_specifiques -
      amortissement;

    const abattement_IS = 38120;
    const pourcentage_IS_sur_abattement = 15;
    const pourcentage_IS_normal_apres_2022 = 25;
    const IS =
      base_imposable < 0
        ? 0
        : base_imposable < abattement_IS
        ? (base_imposable * pourcentage_IS_sur_abattement) / 100
        : (abattement_IS * pourcentage_IS_sur_abattement) / 100 +
          ((base_imposable - abattement_IS) *
            pourcentage_IS_normal_apres_2022) /
            100;

    const report_annee_precendente_arrondi = round(report_annee_precendente, 2);
    const loyers_arrondi = round(loyers, 2);

    const charges_ponctuelles_arrondi = _arrondirEnNegatif(charges_ponctuelles);
    const charges_recurrentes_arrondi = _arrondirEnNegatif(charges_recurrentes);
    const charges_specifiques_arrondi = _arrondirEnNegatif(charges_specifiques);
    const amortissement_arrondi = _arrondirEnNegatif(amortissement);
    const credit_arrondi = _arrondirEnNegatif(total_annee.credit);
    const cashflow_brut_arrondi = round(cashflow_brut, 2);
    const base_imposable_arrondi = round(base_imposable, 2);

    const IS_arrondi = _arrondirEnNegatif(IS);
    const tresorerie_societe = cashflow_brut - IS + disponibilite_n_moins_1;
    const tresorerie_societe_arrondi = round(tresorerie_societe, 2);
    const resultat_distribuable =
      base_imposable - IS > 0 && tresorerie_societe > 0
        ? base_imposable - IS > tresorerie_societe
          ? tresorerie_societe
          : base_imposable - IS
        : 0;
    const resultat_distribuable_arrondi = round(resultat_distribuable, 2);
    const disponibilite = tresorerie_societe - resultat_distribuable;
    disponibilite_n_moins_1 = disponibilite;
    const disponibilite_arrondi = round(disponibilite, 2);
    const pourcentage_net = 70;
    const net_percu = (resultat_distribuable * pourcentage_net) / 100;
    const net_percu_arrondi = round(net_percu, 2);
    const rente_par_mois_is = net_percu / 12;
    const rente_par_mois_is_arrondi = round(rente_par_mois_is, 2);
    cumul_cashflow += net_percu;
    const cumul_cashflow_arrondi = round(cumul_cashflow, 2);
    base_calcul_prix_session -= amortissement;
    const base_calcul_prix_session_arrondi = round(base_calcul_prix_session, 2);
    const capital_restant_arrondi = round(total_annee.capital_restant, 2);
    revenuIS.push({
      annee: annee,
      report_annee_precendente: report_annee_precendente_arrondi,
      loyers: loyers_arrondi,
      capital_restant: capital_restant_arrondi,
      charges_ponctuelles: charges_ponctuelles_arrondi,
      charges_recurrentes: charges_recurrentes_arrondi,
      charges_specifiques: charges_specifiques_arrondi,
      amortissement: amortissement_arrondi,
      credit: credit_arrondi,
      cashflow_brut: cashflow_brut_arrondi,
      base_imposable: base_imposable_arrondi,
      IS: IS_arrondi,
      tresorerie_societe: tresorerie_societe_arrondi,
      resultat_distribuable: resultat_distribuable_arrondi,
      disponibilite: disponibilite_arrondi,
      net_percu: net_percu_arrondi,
      rente_par_mois: rente_par_mois_is_arrondi,
      cumul_cashflow: cumul_cashflow_arrondi,
      base_calcul_prix_session: base_calcul_prix_session_arrondi,
    });
    if (base_imposable < 0) {
      report_annee_precendente = base_imposable;
    } else {
      report_annee_precendente = 0;
    }
  }
  return revenuIS;
}

interface IDonneeFiscaleAnnualise {
  [annee: number]: IDonneeFiscale;
}

function calculerDonneesFiscalesAnnualisees(
  donnees_fiscales: IDonneeFiscale[],
  duree_calcul: number,
  annee_depart: number
) {
  if (donnees_fiscales.length === 0) {
    donnees_fiscales.push({
      annee: annee_depart,
      revenu_net_imposable: 0,
      quotient_familial: 1,
    });
  }
  const donnees_fiscales_annulalisees = donnees_fiscales.reduce(
    (acc, donnee_fiscale) => {
      acc[donnee_fiscale.annee] = donnee_fiscale;
      return acc;
    },
    {} as IDonneeFiscaleAnnualise
  );
  let revenu_net_imposable = donnees_fiscales[0].revenu_net_imposable;
  let quotient_familial = donnees_fiscales[0].quotient_familial;

  for (let numero_annee = 0; numero_annee <= duree_calcul; numero_annee++) {
    const annee = annee_depart + numero_annee;

    if (annee in donnees_fiscales_annulalisees) {
      revenu_net_imposable =
        donnees_fiscales_annulalisees[annee].revenu_net_imposable;
      quotient_familial =
        donnees_fiscales_annulalisees[annee].quotient_familial;
    } else {
      donnees_fiscales_annulalisees[annee] = {
        annee,
        revenu_net_imposable,
        quotient_familial,
      };
    }
  }
  return donnees_fiscales_annulalisees;
}

export function calculer_tableau_annualise(
  variante: IVariante,
  tableau_amortissement: LigneAmortissement[],
  donnees_fiscales: IDonneesFiscales
) {
  const total_par_an: TotalParAn = {};
  if (tableau_amortissement.length === 0) {
    return {
      FONCIER: [],
      BIC_LMNP: [],
      BIC_LMP: [],
      IS: [],
    };
  }
  const loyers_mensuel = variante.lots.reduce((acc, lot) => {
    acc += lot.loyer_projete;
    return acc;
  }, 0);
  const nombre_mois_dans_une_annee = 12;
  const date = parse(variante.date_deblocage, "dd/MM/yyyy", new Date());
  const date_premier_prelevement = addMonths(date, 1);
  const mois_depart = getMonth(date_premier_prelevement);
  const annee_depart = getYear(date_premier_prelevement);
  let index = 1;
  for (
    let numero_annee = 0;
    numero_annee <= variante.duree_calcul;
    numero_annee++
  ) {
    const annee = annee_depart + numero_annee;

    let index_mois_depart = 0;
    if (numero_annee === 0) {
      index_mois_depart = mois_depart;
    }

    let index_dernier_mois = nombre_mois_dans_une_annee - 1;
    if (numero_annee === variante.duree_calcul) {
      index_dernier_mois = mois_depart - 1;
    }

    for (
      let numero_mois = index_mois_depart;
      numero_mois <= index_dernier_mois;
      numero_mois++
    ) {
      const total = get(total_par_an, annee, {
        nb_mois: 0,
        loyers: 0,
        capital_restant: 0,
        credit: 0,
        interets: 0,
        assurance_pret: 0,
        frais_de_gestion: 0,
        GLI: 0,
        taxe_fonciere: 0,
        assurance_PNO: 0,
      });
      total.nb_mois += 1;
      total.loyers += loyers_mensuel;
      total.frais_de_gestion +=
        (variante.frais_de_gestion / 100) * loyers_mensuel;
      total.GLI += (variante.GLI / 100) * loyers_mensuel;
      total.taxe_fonciere += variante.taxe_fonciere / 12;
      total.assurance_PNO += variante.assurance_PNO / 12;
      if (index < tableau_amortissement.length) {
        const ligneAmortissement = tableau_amortissement[index];
        total.interets += ligneAmortissement.interets;
        total.credit += ligneAmortissement.echeance;
        total.assurance_pret += ligneAmortissement.assurance;
        total.capital_restant = ligneAmortissement.capital_restant;
      }
      total_par_an[annee] = total;
      index += 1;
    }
    index_mois_depart = 0;
  }

  const montant_travaux = variante.travaux.reduce((acc, travail) => {
    acc += travail.montant;
    return acc;
  }, 0);

  const donnees_fiscales_annulalisees = calculerDonneesFiscalesAnnualisees(
    donnees_fiscales,
    variante.duree_calcul,
    annee_depart
  );

  return {
    FONCIER: _calcul_foncier(
      total_par_an,
      montant_travaux,
      donnees_fiscales_annulalisees
    ),
    BIC_LMNP: _calcul_BIC_LMNP(
      total_par_an,
      montant_travaux,
      variante,
      donnees_fiscales_annulalisees
    ),
    BIC_LMP: _calcul_BIC_LMP(
      total_par_an,
      montant_travaux,
      variante,
      donnees_fiscales_annulalisees
    ),
    IS: _calcul_IS(total_par_an, montant_travaux, variante),
  };
}
