import { AppThunk } from "store";
import { IAlert } from "types";
import * as types from "./alertsActionsTypes";

export function showAlert(alert: IAlert) {
  return {
    type: types.SHOW_ALERT,
    alert,
  };
}

export function hideAlert(alert: IAlert) {
  return {
    type: types.HIDE_ALERT,
    alert,
  };
}

function showAndHideAfter10s(
  title = "",
  type: "success" | "danger" = "success"
): AppThunk<void> {
  const alert: IAlert = {
    id: Date.now().toString(),
    title,
    type,
  };
  return (dispatch) => {
    dispatch(showAlert(alert));
    setTimeout(() => dispatch(hideAlert(alert)), 10000);
  };
}

export function showSuccess(title: string) {
  return showAndHideAfter10s(title, "success");
}

export function showError(title: string) {
  return showAndHideAfter10s(title, "danger");
}
