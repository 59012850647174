import React from "react";
import { Tooltip, TooltipProps } from "@patternfly/react-core";
import { OutlinedQuestionCircleIcon } from "@patternfly/react-icons";

interface QuestionMarkHelperProps {
  content: React.ReactNode;
  position?: TooltipProps["position"];
  [x: string]: any;
}

export default function QuestionMarkHelper({
  content,
  position = "right",
  ...props
}: QuestionMarkHelperProps) {
  return (
    <Tooltip position={position} content={content}>
      <OutlinedQuestionCircleIcon {...props} />
    </Tooltip>
  );
}
