import React from "react";
import { Input } from "components/form";
import * as Yup from "yup";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Modal, ModalVariant } from "@patternfly/react-core";

const SchemaCreerVariante = Yup.object().shape({
  nom: Yup.string().required("Le nom d'un variante est nécessaire"),
});

function CreerNouvelleVarianteButton({ ...props }: { [x: string]: any }) {
  const { submitForm } = useFormikContext();
  return (
    <Button {...props} onClick={submitForm}>
      Créer
    </Button>
  );
}

interface NouvelleVarianteModalProps {
  isOpen: boolean;
  hide: () => void;
  onSubmit: (v: { nom: string }) => void;
}

export default function NouvelleVarianteModal({
  isOpen,
  hide,
  onSubmit,
}: NouvelleVarianteModalProps) {
  return (
    <Formik
      initialValues={{
        nom: "",
      }}
      validationSchema={SchemaCreerVariante}
      onSubmit={onSubmit}
    >
      <Modal
        variant={ModalVariant.medium}
        title="Créer une nouvelle variante"
        isOpen={isOpen}
        onClose={hide}
        actions={[
          <CreerNouvelleVarianteButton key="confirm" variant="primary" />,
          <Button key="cancel" variant="link" onClick={hide}>
            Annuler
          </Button>,
        ]}
      >
        <Form id="nouveau_variante_form" className="pf-c-form">
          <Input
            id="nouveau_variante_form__nom"
            data-testid="nouveau_variante_form__nom"
            label="Nom"
            name="nom"
            isRequired
            autoFocus
          />
        </Form>
      </Modal>
    </Formik>
  );
}
