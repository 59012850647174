import { isEmpty } from "lodash";
import { Modal, ModalVariant } from "@patternfly/react-core";
import { useEffect, useState } from "react";
import { getMessages, lireMessage } from "services/api";
import { IMessage } from "types";
import { ALLOWED_TAG_LIST, Interweave } from "interweave";

export default function Messages() {
  const [messages, setMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    getMessages().then((reponse) => {
      setMessages(reponse.data.results);
    });
  }, []);

  if (isEmpty(messages)) return null;

  const cacherEtLireMessage = (message: IMessage) => {
    setMessages(messages.filter((m) => m.id !== message.id));
    lireMessage(message);
  };

  return (
    <div>
      {messages.map((message, i) => (
        <Modal
          key={message.id}
          title={message.title}
          isOpen={i===0}
          onClose={() => cacherEtLireMessage(message)}
          variant={ModalVariant.large}
        >
          <Interweave
            content={message.content}
            allowList={ALLOWED_TAG_LIST.concat("iframe")}
          />
        </Modal>
      ))}
    </div>
  );
}
