import {
  IProjet,
  INouveauProjet,
  INouvelleVariante,
  IVariante,
  ICredentials,
  ICurrentUser,
  ITokenFromAPI,
  ISignUpForm,
  INewPassword,
  IPartage,
  IDonneeFiscale,
  ITokens,
  IVarianteParDefaut,
  INouvelleVarianteParDefaut,
  IMessage,
} from "types";
import http from "./http";

export function refreshTokens(tokens: ITokens): Promise<ITokenFromAPI> {
  return http
    .post("/auth/jwt/refresh/", { refresh: tokens.refreshToken })
    .then((response) => response.data);
}

export function signUp(form: ISignUpForm): Promise<ICurrentUser> {
  return http.post("/auth/signup/", form).then((response) => response.data);
}

export function login(credentials: ICredentials): Promise<ITokenFromAPI> {
  return http
    .post("/auth/jwt/create/", credentials)
    .then((response) => response.data);
}

export function getCurrentUser(): Promise<ICurrentUser> {
  return http.get("/auth/users/me/").then((response) => response.data);
}

export function updateCurrentUser(
  currentUser: ICurrentUser
): Promise<ICurrentUser> {
  return http
    .put("/auth/users/me/", currentUser)
    .then((response) => response.data);
}

export function resetPassword(email: string) {
  return http.post("/auth/users/reset_password/", { email });
}

export function setPassword({
  current_password,
  new_password,
  re_new_password,
}: INewPassword) {
  return http.post("/auth/users/set_password/", {
    current_password,
    new_password,
    re_new_password,
  });
}

export function confirmResetPassword(
  uid: string,
  token: string,
  password: string
) {
  return http.post("/auth/users/reset_password_confirm/", {
    uid,
    token,
    new_password: password,
    re_new_password: password,
  });
}

export function activateAccount(uid: string, token: string) {
  return http.post("/auth/users/activation/", {
    uid,
    token,
  });
}

// projets
interface IGetProjects {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IProjet[];
  };
}

export function getProjets(): Promise<IGetProjects> {
  return http.get("/api/bopil/projets/");
}

interface IGetProjet {
  data: IProjet;
}

export function getProjet(id: string): Promise<IGetProjet> {
  return http.get(`/api/bopil/projets/${id}/`);
}
interface ICreateProject {
  data: IProjet;
}

export function createProjet(projet: INouveauProjet): Promise<ICreateProject> {
  return http.post("/api/bopil/projets/", projet);
}

interface IUpdateProject {
  data: IProjet;
}

export function updateProjet(projet: IProjet): Promise<IUpdateProject> {
  return http.put(`/api/bopil/projets/${projet.id}/`, projet);
}

export function deleteProjet(projet: IProjet): Promise<void> {
  return http.delete(`/api/bopil/projets/${projet.id}/`);
}

// Variantes

interface IGetVariantes {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IVariante[];
  };
}

export function getVariantes(): Promise<IGetVariantes> {
  return http.get("/api/bopil/variantes/");
}

interface IGetVariante {
  data: IVariante;
}

export function getVariante(id: string): Promise<IGetVariante> {
  return http.get(`/api/bopil/variantes/${id}/`);
}
interface IVarianteCreation {
  data: IVariante;
}

export function createVariante(
  variante: INouvelleVariante
): Promise<IVarianteCreation> {
  return http.post("/api/bopil/variantes/", variante);
}

interface IUpdateVariante {
  data: IVariante;
}

export function updateVariante(variante: IVariante): Promise<IUpdateVariante> {
  return http.put(`/api/bopil/variantes/${variante.id}/`, variante);
}

export function deleteVariante(variante: IVariante): Promise<void> {
  return http.delete(`/api/bopil/variantes/${variante.id}/`);
}

// Options

interface ICreateOption {
  data: IVarianteParDefaut;
}

export function changeVarianteParDefaut(
  variante: INouvelleVarianteParDefaut
): Promise<ICreateOption> {
  return http.post(`/api/bopil/options/`, variante);
}

interface IGetOptions {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IVarianteParDefaut[];
  };
}

export function getVariantesParDefaut(): Promise<IGetOptions> {
  return http.get("/api/bopil/options/");
}

export function getVarianteParDefaut(): Promise<IVarianteParDefaut> {
  return getVariantesParDefaut().then((response) => {
    const r = response.data.results;
    if (r.length > 0) {
      return Promise.resolve(r[0]);
    }
    return Promise.reject("pas d'options par defaut");
  });
}

export function supprimerVarianteParDefaut(
  variante: IVarianteParDefaut
): Promise<void> {
  return http.delete(`/api/bopil/options/${variante.id}/`);
}

// partages

interface IGetPartagesForProjet {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IPartage[];
  };
}

export function getPartagesForProjet(
  projet: IProjet
): Promise<IGetPartagesForProjet> {
  return http.get(`/api/bopil/partages/projets/${projet.id}/`);
}

export function partagerUnProjet(
  projet: IProjet,
  email: string
): Promise<void> {
  return http.post(`/api/bopil/partages/projets/${projet.id}/`, { email });
}

export function supprimerPartage(partage: IPartage): Promise<void> {
  return http.delete(`/api/bopil/partages/${partage.id}/`);
}

interface IGetProjetsPartages {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IProjet[];
  };
}

export function getProjetsPartages(): Promise<IGetProjetsPartages> {
  return http.get("/api/bopil/partages/projets/");
}

// Donnees Fiscales

interface IGetDonneesFiscales {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IDonneeFiscale[];
  };
}

export function getDonneesFiscales(): Promise<IGetDonneesFiscales> {
  return http.get("/api/bopil/donnees-fiscales/");
}

export function supprimerDonneeFiscale(
  donnee_fiscale: IDonneeFiscale
): Promise<void> {
  return http.delete(`/api/bopil/donnees-fiscales/${donnee_fiscale.id}/`);
}

interface IDonneeFiscaleCreation {
  data: IDonneeFiscale;
}

export function creerDonneeFiscale(
  donnee_fiscale: Partial<IDonneeFiscale>
): Promise<IDonneeFiscaleCreation> {
  return http.post("/api/bopil/donnees-fiscales/", donnee_fiscale);
}

// Messages

interface IGetMessage {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IMessage[];
  };
}

export function getMessages(): Promise<IGetMessage> {
  return http.get("/messages/?key=bopil");
}

export function lireMessage(message: IMessage): Promise<void> {
  return http.delete(`/messages/${message.id}/`);
}
