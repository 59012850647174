import { Navigate, Route, Routes } from "react-router-dom";
import "@patternfly/react-core/dist/styles/base.css";
import "@patternfly/patternfly/components/Table/table.css";
import "@patternfly/patternfly/components/DataList/data-list.css";
import "@patternfly/patternfly/components/InlineEdit/inline-edit.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import PageProjets from "projets/PageProjets";
import PageProjet from "projets/PageProjet";
import LoginPage from "auth/LoginPage";
import SignUpPage from "auth/SignUpPage";
import ForgotPasswordPage from "auth/ForgotPasswordPage";
import PasswordResetPage from "auth/PasswordResetPage";
import Page404 from "layout/Page404";
import PageMonCompte from "parametres/PageMonCompte";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import NotAuthenticatedPage from "layout/NotAuthenticatedPage";
import AuthenticatedPage from "layout/AuthenticatedPage";
import PageConclusion from "conclusion/PageConclusion";

registerLocale("fr", fr);

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Navigate replace to="projets" />} />
        <Route element={<NotAuthenticatedPage />}>
          <Route path="se_connecter" element={<LoginPage />} />
          <Route path="creer_un_compte" element={<SignUpPage />} />
          <Route path="mot_de_passe_oublie" element={<ForgotPasswordPage />} />
          <Route
            path="nouveau_mot_de_passe/:uid/:token"
            element={<PasswordResetPage />}
          />
        </Route>
        <Route element={<AuthenticatedPage />}>
          <Route path="projets">
            <Route index element={<PageProjets />} />
            <Route path=":projet_id" element={<PageProjet />} />
          </Route>
          <Route path="mon_compte" element={<PageMonCompte />} />
        </Route>
        <Route
          path="conclusion/:projet_id/variantes/:variante_id"
          element={<PageConclusion />}
        />
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default App;
