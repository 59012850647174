import React from "react";
import { EmptyState, EmptyStateIcon } from "@patternfly/react-core";

export default function LoadingState() {
  const Spinner = () => (
    <span
      className="pf-c-spinner"
      role="progressbar"
      aria-valuetext="Chargement..."
    >
      <span className="pf-c-spinner__clipper" />
      <span className="pf-c-spinner__lead-ball" />
      <span className="pf-c-spinner__tail-ball" />
    </span>
  );
  return (
    <EmptyState>
      <EmptyStateIcon variant="container" component={Spinner} />
    </EmptyState>
  );
}
