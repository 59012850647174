import {
  ListVariant,
  LoginMainFooterBandItem,
  LoginPage,
} from "@patternfly/react-core";
import LoginForm from "./LoginForm";
import Logo from "images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { LoginText } from "./info";
import { useAuth } from "./authContext";
import { parseErreur } from "./erreur";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { showError } from "alerts/alertsActions";

export default function BopilLoginPage() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <LoginPage
      footerListVariants={ListVariant.inline}
      brandImgSrc={Logo}
      brandImgAlt="Bopil logo"
      textContent={LoginText}
      loginTitle="Se connecter"
      loginSubtitle="Utilisez votre email et mot de passe BOPIL."
      signUpForAccountMessage={
        <LoginMainFooterBandItem>
          Pas encore de compte ? <Link to="/creer_un_compte">Créer en un.</Link>
        </LoginMainFooterBandItem>
      }
      forgotCredentials={
        <LoginMainFooterBandItem>
          <Link to="/mot_de_passe_oublie">Mot de passe oublié ?</Link>
        </LoginMainFooterBandItem>
      }
    >
      <LoginForm
        onSubmit={(c) => {
          login(c)
            .then(() => navigate("/"))
            .catch((e) => {
              const erreur = parseErreur(e?.response?.data);
              if (erreur) {
                dispatch(showError(erreur));
              } else {
                dispatch(
                  showError(
                    "Nous sommes désolés, une erreur est survenue. Veuillez contacter le service client si l'erreur persiste. Merci."
                  )
                );
              }
            });
        }}
      />
    </LoginPage>
  );
}
