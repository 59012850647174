import axios from "axios";
import { getTokens } from "./localStorage";

const baseURL = process.env.REACT_APP_BACKEND_HOST || "https://api.bopil.immo";

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use((config) => {
  const token = getTokens();
  if (config && config.headers) {
    if (token) {
      config.headers.Authorization = `Bearer ${token.accessToken}`;
    }
    config.headers.Application = "BOPIL";
  }
  return config;
});

export default axios;
