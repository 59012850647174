import { useState } from "react";
import { DatePicker, Input, Number, Select } from "components/form";
import { Formik, Form } from "formik";
import {
  ILotForm,
  IVarianteForm,
  INouvelleVariante,
  ITravailForm,
} from "types";
import {
  Button,
  ExpandableSection,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  TextInput,
} from "@patternfly/react-core";
import * as Yup from "yup";
import {
  chaineEnNombre,
  chaineVersMasqueEuro,
  chaineVersMasqueSurface,
} from "components/form/transform";
import { PlusCircleIcon } from "@patternfly/react-icons";
import TrashButtonIcon from "components/TrashButtonIcon";
import QuestionMarkHelper from "components/QuestionMarkHelper";
import { round } from "lodash";

function removeItem<T>(array: T[], index: number) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

const VarianteSchema = Yup.object().shape({
  prix_achat_FAI: Yup.string(),
  prix_de_revente: Yup.string(),
  frais_de_notaire: Yup.string(),
  frais_de_garanties: Yup.string(),
  apport_eventuel: Yup.string(),
  duree_calcul: Yup.string(),
  duree_pret: Yup.string(),
  differe: Yup.string(),
  taux_assurance: Yup.string(),
  taux_interet: Yup.string(),
  date_deblocage: Yup.string().required("La date de déblocage est obligatoire"),
  frais_de_gestion: Yup.string(),
  GLI: Yup.string(),
  taxe_fonciere: Yup.string(),
  assurance_PNO: Yup.string(),
  charges_copropriete: Yup.string(),
  frais_comptable: Yup.string(),
  CFE: Yup.string(),
  travaux: Yup.array().of(
    Yup.object().shape({
      designation: Yup.string(),
      montant: Yup.string(),
    })
  ),
  lots: Yup.array().of(
    Yup.object().shape({
      designation: Yup.string(),
      situation_locative: Yup.string(),
      superficie: Yup.string(),
      loyer_actuel: Yup.string(),
      loyer_projete: Yup.string(),
    })
  ),
});

function depuisVarianteVersFormulaire(
  variante: INouvelleVariante
): IVarianteForm {
  return {
    ...variante,
    prix_achat_FAI: variante.prix_achat_FAI
      ? variante.prix_achat_FAI.toString()
      : "0",
    prix_de_revente: variante.prix_de_revente
      ? variante.prix_de_revente.toString()
      : "0",
    frais_de_notaire: variante.frais_de_notaire
      ? variante.frais_de_notaire.toString()
      : "0",
    frais_de_garanties: variante.frais_de_garanties
      ? variante.frais_de_garanties.toString()
      : "0",
    apport_eventuel: variante.apport_eventuel
      ? variante.apport_eventuel.toString()
      : "0",
    duree_calcul: variante.duree_calcul
      ? variante.duree_calcul.toString()
      : "0",
    duree_pret: variante.duree_pret.toString(),
    differe: variante.differe.toString(),
    taux_assurance: variante.taux_assurance.toString(),
    taux_interet: variante.taux_interet.toString(),
    date_deblocage: variante.date_deblocage || "",
    frais_de_gestion: variante.frais_de_gestion.toString(),
    GLI: variante.GLI.toString(),
    taxe_fonciere: variante.taxe_fonciere.toString(),
    assurance_PNO: variante.assurance_PNO.toString(),
    charges_copropriete: variante.charges_copropriete.toString(),
    frais_comptable: variante.frais_comptable.toString(),
    CFE: variante.CFE.toString(),
    travaux: variante.travaux.map((t) => ({
      designation: t.designation,
      montant: t.montant.toString(),
    })),
    lots: variante.lots.map((l) => ({
      designation: l.designation,
      situation_locative: l.situation_locative,
      superficie: l.superficie.toString(),
      loyer_actuel: l.loyer_actuel.toString(),
      loyer_projete: l.loyer_projete.toString(),
    })),
  };
}

function depuisFormulaireVersVariante(
  varianteInitiale: INouvelleVariante,
  form: IVarianteForm
): INouvelleVariante | undefined {
  try {
    return {
      ...varianteInitiale,
      prix_achat_FAI: chaineEnNombre(form.prix_achat_FAI),
      prix_de_revente: chaineEnNombre(form.prix_de_revente),
      frais_de_notaire: chaineEnNombre(form.frais_de_notaire),
      frais_de_garanties: chaineEnNombre(form.frais_de_garanties),
      apport_eventuel: chaineEnNombre(form.apport_eventuel),
      duree_calcul: chaineEnNombre(form.duree_calcul),
      duree_pret: chaineEnNombre(form.duree_pret),
      differe: chaineEnNombre(form.differe),
      taux_assurance: chaineEnNombre(form.taux_assurance),
      taux_interet: chaineEnNombre(form.taux_interet),
      date_deblocage: form.date_deblocage,
      frais_de_gestion: chaineEnNombre(form.frais_de_gestion),
      GLI: chaineEnNombre(form.GLI),
      taxe_fonciere: chaineEnNombre(form.taxe_fonciere),
      assurance_PNO: chaineEnNombre(form.assurance_PNO),
      charges_copropriete: chaineEnNombre(form.charges_copropriete),
      frais_comptable: chaineEnNombre(form.frais_comptable),
      CFE: chaineEnNombre(form.CFE),
      travaux: form.travaux.map((t) => ({
        ...t,
        montant: chaineEnNombre(t.montant),
      })),
      lots: form.lots.map((p) => ({
        ...p,
        superficie: chaineEnNombre(p.superficie),
        loyer_actuel: chaineEnNombre(p.loyer_actuel),
        loyer_projete: chaineEnNombre(p.loyer_projete),
      })),
    };
  } catch (e) {}
}

interface VarianteFormProps {
  variante: INouvelleVariante;
  onSubmit: (variante: INouvelleVariante) => void;
  buttonText?: string;
  estVarianteParDefaut?: boolean;
}

export default function VarianteForm({
  variante,
  onSubmit,
  buttonText = "Lancer le calcul",
  estVarianteParDefaut = false,
}: VarianteFormProps) {
  const [isLotsExpanded, setIsLotsExpanded] = useState(true);
  const [isTravauxExpanded, setIsTravauxExpanded] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={depuisVarianteVersFormulaire(variante)}
      validationSchema={VarianteSchema}
      onSubmit={(values, actions) => {
        const s = depuisFormulaireVersVariante(variante, values);
        if (s) {
          onSubmit(s);
          actions.setSubmitting(false);
        }
      }}
    >
      {({ values, setValues }) => (
        <Form id="form" className="pf-c-form">
          <Grid hasGutter span={12}>
            <GridItem span={12} lg={4}>
              <Grid hasGutter span={6}>
                <GridItem span={12}>Achat</GridItem>
                <GridItem>
                  <Number
                    id="prix_achat_FAI"
                    data-testid="prix_achat_FAI"
                    label="Prix achat FAI"
                    labelIcon={
                      <QuestionMarkHelper content="Frais d'Agence Inclus" />
                    }
                    isDisabled={estVarianteParDefaut}
                    name="prix_achat_FAI"
                    variante="monnaie"
                    onUpdate={(value) => {
                      const s = depuisFormulaireVersVariante(variante, {
                        ...values,
                        prix_achat_FAI: value,
                      });
                      if (s) {
                        onSubmit(s);
                      }
                    }}
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="prix_de_revente"
                    data-testid="prix_de_revente"
                    label="Prix de revente"
                    name="prix_de_revente"
                    variante="monnaie"
                    isDisabled={estVarianteParDefaut}
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="frais_de_notaire"
                    data-testid="frais_de_notaire"
                    label="Frais de notaire"
                    name="frais_de_notaire"
                    variante="monnaie"
                    isDisabled={estVarianteParDefaut}
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="frais_de_garanties"
                    data-testid="frais_de_garanties"
                    label="Frais de garanties"
                    name="frais_de_garanties"
                    variante="monnaie"
                    isDisabled={estVarianteParDefaut}
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="apport_eventuel"
                    data-testid="apport_eventuel"
                    label="Apport éventuels"
                    name="apport_eventuel"
                    variante="monnaie"
                    isDisabled={estVarianteParDefaut}
                  />
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem span={12} lg={4}>
              <Grid hasGutter span={6}>
                <GridItem span={12}>Financement</GridItem>
                <GridItem>
                  <Number
                    id="duree_pret"
                    data-testid="duree_pret"
                    label="Durée du prêt"
                    name="duree_pret"
                    variante="annee"
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="taux_interet"
                    data-testid="taux_interet"
                    label="Taux intérêt"
                    name="taux_interet"
                    variante="pourcentage"
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="taux_assurance"
                    data-testid="taux_assurance"
                    label="Taux assurance"
                    name="taux_assurance"
                    variante="pourcentage"
                  />
                </GridItem>
                <GridItem>
                  <DatePicker
                    id="date_deblocage"
                    data-testid="date_deblocage"
                    label="Date deblocage"
                    name="date_deblocage"
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="differe"
                    data-testid="differe"
                    label="Différé"
                    name="differe"
                    variante="mois"
                  />
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem span={12} lg={4}>
              <Grid hasGutter span={6}>
                <GridItem span={12}>Gestion locative</GridItem>
                <GridItem>
                  <Number
                    id="taxe_fonciere"
                    data-testid="taxe_fonciere"
                    label="Taxe foncière"
                    name="taxe_fonciere"
                    variante="monnaie"
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="assurance_PNO"
                    data-testid="assurance_PNO"
                    label="Assurance PNO"
                    labelIcon={
                      <QuestionMarkHelper
                        content="Assurance Propriétaire Non Occupant"
                        className="text-xs"
                      />
                    }
                    name="assurance_PNO"
                    variante="monnaie"
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="frais_de_gestion"
                    data-testid="frais_de_gestion"
                    label="Frais de gestion TTC"
                    name="frais_de_gestion"
                    variante="pourcentage"
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="GLI"
                    data-testid="GLI"
                    label="GLI TTC"
                    labelIcon={
                      <QuestionMarkHelper content="Garantie Loyer Impayé" />
                    }
                    name="GLI"
                    variante="pourcentage"
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="charges_copropriete"
                    data-testid="charges_copropriete"
                    label="Charges de copropriété"
                    name="charges_copropriete"
                    variante="monnaie"
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="frais_comptable"
                    data-testid="frais_comptable"
                    label="Frais comptable"
                    name="frais_comptable"
                    variante="monnaie"
                  />
                </GridItem>
                <GridItem>
                  <Number
                    id="CFE"
                    data-testid="CFE"
                    label="CFE"
                    labelIcon={
                      <QuestionMarkHelper
                        content="Cotisation Foncière des Entreprises"
                        className="text-xs"
                      />
                    }
                    name="CFE"
                    variante="monnaie"
                  />
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
          <ExpandableSection
            toggleText="Lots"
            onToggle={() => setIsLotsExpanded(!isLotsExpanded)}
            isExpanded={isLotsExpanded}
            style={{ marginTop: "-1.5rem" }}
          >
            <table className="pf-c-table pf-m-compact pf-m-grid-md pf-m-no-border-rows">
              <thead>
                <tr>
                  <th role="columnheader" scope="col"></th>
                  <th role="columnheader" scope="col">
                    Désignation
                  </th>
                  <th role="columnheader" scope="col">
                    Situation
                  </th>
                  <th role="columnheader" scope="col">
                    Loyer actuel (cc)
                  </th>
                  <th role="columnheader" scope="col">
                    Loyer projeté (cc)
                  </th>
                  <th role="columnheader" scope="col">
                    Superficie
                  </th>
                  <th role="columnheader" scope="col">
                    Prix au m²
                  </th>
                  <th role="columnheader" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {values.lots.map((l, i) => {
                  const id_designation = `lots[${i}].designation`;
                  const id_superficie = `lots[${i}].superficie`;
                  const id_situation_locative = `lots[${i}].situation_locative`;
                  const id_loyer_actuel = `lots[${i}].loyer_actuel`;
                  const id_loyer_projete = `lots[${i}].loyer_projete`;
                  const id_supprimer_lots = `lots[${i}].bouton_supprimer`;
                  return (
                    <tr key={`lots${i}`}>
                      <td className="text-center">{i + 1}</td>
                      <td>
                        <Input
                          id={id_designation}
                          data-testid={id_designation}
                          name={id_designation}
                          type="text"
                        />
                      </td>

                      <td>
                        <Select
                          id={id_situation_locative}
                          data-testid={id_situation_locative}
                          name={id_situation_locative}
                          option={l.situation_locative}
                          options={[
                            {
                              label: "Libre",
                              value: "LIBRE",
                            },
                            {
                              label: "Occupé",
                              value: "OQP",
                            },
                          ]}
                        />
                      </td>
                      <td>
                        <Number
                          id={id_loyer_actuel}
                          data-testid={id_loyer_actuel}
                          name={id_loyer_actuel}
                          type="text"
                          variante="monnaie"
                        />
                      </td>
                      <td>
                        <Number
                          id={id_loyer_projete}
                          data-testid={id_loyer_projete}
                          name={id_loyer_projete}
                          type="text"
                          variante="monnaie"
                        />
                      </td>
                      <td>
                        <Number
                          id={id_superficie}
                          data-testid={id_superficie}
                          name={id_superficie}
                          type="text"
                          variante="surface"
                        />
                      </td>
                      <td className="text-center font-mono">
                        {`${chaineVersMasqueEuro(
                          chaineEnNombre(l.superficie) === 0
                            ? 0
                            : round(
                                chaineEnNombre(l.loyer_projete) /
                                  chaineEnNombre(l.superficie),
                                2
                              )
                        )} / m²`}
                      </td>
                      <td>
                        <TrashButtonIcon
                          tabIndex={-1}
                          type="button"
                          data-testid={id_supprimer_lots}
                          onClick={() => {
                            setValues({
                              ...values,
                              lots: removeItem<ILotForm>(values.lots, i),
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td>
                    <Button
                      id="ajouter_lot_bouton"
                      data-testid="ajouter_lot_bouton"
                      variant="control"
                      isBlock
                      type="button"
                      onClick={() => {
                        setValues({
                          ...values,
                          lots: [
                            ...values.lots,
                            {
                              designation: "",
                              superficie: "0",
                              situation_locative: "LIBRE",
                              loyer_actuel: "0",
                              loyer_projete: "0",
                            },
                          ],
                        });
                      }}
                    >
                      <PlusCircleIcon className="mr-2" /> Ajouter un lot
                    </Button>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <TextInput
                      id="somme_loyer_projete"
                      isReadOnly
                      type="text"
                      className="font-mono"
                      value={chaineVersMasqueEuro(
                        Object.values(values.lots).reduce((acc, p) => {
                          acc += chaineEnNombre(p.loyer_projete);
                          return acc;
                        }, 0)
                      )}
                    />
                  </td>
                  <td>
                    <TextInput
                      id="somme_superficie"
                      isReadOnly
                      type="text"
                      className="font-mono"
                      value={chaineVersMasqueSurface(
                        Object.values(values.lots).reduce((acc, p) => {
                          acc += chaineEnNombre(p.superficie);
                          return acc;
                        }, 0)
                      )}
                    />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </ExpandableSection>
          <Grid hasGutter span={12}>
            <GridItem span={12} lg={8}>
              <ExpandableSection
                toggleText="Charges initiales"
                onToggle={() => setIsTravauxExpanded(!isTravauxExpanded)}
                isExpanded={isTravauxExpanded}
              >
                <table className="pf-c-table pf-m-compact pf-m-grid-md pf-m-no-border-rows">
                  <thead>
                    <tr>
                      <th role="columnheader" scope="col"></th>
                      <th role="columnheader" scope="col">
                        Désignation
                      </th>
                      <th role="columnheader" scope="col">
                        Montant travaux
                      </th>
                      <th role="columnheader" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.travaux.map((t, i) => {
                      const id_designation = `travaux[${i}].designation`;
                      const id_montant = `travaux[${i}].montant`;
                      const id_supprimer_travail = `travaux[${i}].bouton_supprimer`;
                      return (
                        <tr key={`travail${i}`}>
                          <td className="text-center">{i + 1}</td>
                          <td>
                            <Input
                              id={id_designation}
                              data-testid={id_designation}
                              name={id_designation}
                              type="text"
                            />
                          </td>
                          <td>
                            <Number
                              id={id_montant}
                              data-testid={id_montant}
                              name={id_montant}
                              type="text"
                              variante="monnaie"
                            />
                          </td>
                          <td className="text-center">
                            <TrashButtonIcon
                              tabIndex={-1}
                              type="button"
                              data-testid={id_supprimer_travail}
                              onClick={() => {
                                setValues({
                                  ...values,
                                  travaux: removeItem<ITravailForm>(
                                    values.travaux,
                                    i
                                  ),
                                });
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td>
                        <Button
                          id="ajouter_travaux_bouton"
                          data-testid="ajouter_travaux_bouton"
                          variant="control"
                          isBlock
                          type="button"
                          onClick={() => {
                            setValues({
                              ...values,
                              travaux: [
                                ...values.travaux,
                                {
                                  designation: "",
                                  montant: "0",
                                },
                              ],
                            });
                          }}
                        >
                          <PlusCircleIcon className="mr-2" /> Ajouter une charge
                          initiale
                        </Button>
                      </td>
                      <td>
                        <TextInput
                          id="somme_montant_travaux"
                          isReadOnly
                          type="text"
                          className="font-mono"
                          value={chaineVersMasqueEuro(
                            Object.values(values.travaux).reduce(
                              (acc, charge) => {
                                acc += chaineEnNombre(charge.montant);
                                return acc;
                              },
                              0
                            )
                          )}
                        />
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </ExpandableSection>
            </GridItem>
          </Grid>

          <Flex>
            <FlexItem>
              <Button
                id="calculer_variante_bouton"
                data-testid="calculer_variante_bouton"
                variant="primary"
                type="submit"
              >
                {buttonText}
              </Button>
            </FlexItem>
            <FlexItem>sur</FlexItem>
            <FlexItem>
              <Number
                id="duree_calcul"
                data-testid="duree_calcul"
                name="duree_calcul"
                variante="annee"
                isDisabled={estVarianteParDefaut}
              />
            </FlexItem>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
