import React, { useState } from "react";
import { Button, Flex, FlexItem } from "@patternfly/react-core";
import { PencilAltIcon } from "@patternfly/react-icons";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Input } from "components/form";

interface TextEditableProps {
  text: string;
  onSubmit: (text: string) => void;
  children: React.ReactNode;
  [x: string]: any;
}

const TextEditableSchema = Yup.object().shape({
  text: Yup.string().required("obligatoire"),
});

export default function TextEditable({
  text,
  onSubmit,
  children,
  ...props
}: TextEditableProps) {
  const [editModeOn, setEditModeOne] = useState(false);

  return editModeOn ? (
    <Formik
      initialValues={{ text }}
      validationSchema={TextEditableSchema}
      onSubmit={(v) => {
        setEditModeOne(false);
        onSubmit(v.text);
      }}
    >
      {({ isValid, dirty }) => (
        <Form className="pf-c-inline-edit pf-m-inline-editable" {...props}>
          <div className="pf-c-inline-edit__group">
            <div className="pf-c-inline-edit__input">
              <Input id="text" name="text" type="text" isRequired autoFocus />
            </div>
            <div className="pf-c-inline-edit__group pf-m-action-group pf-m-icon-group">
              <Button
                variant="primary"
                type="submit"
                isDisabled={!(isValid && dirty)}
              >
                Enregistrer
              </Button>
              <div className="pf-c-inline-edit__action">
                <Button
                  variant="link"
                  type="button"
                  onClick={() => setEditModeOne(false)}
                >
                  Annuler
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <Flex>
      <FlexItem>{children}</FlexItem>
      <FlexItem>
        <Button
          variant="plain"
          type="button"
          onClick={() => setEditModeOne(true)}
          icon={<PencilAltIcon />}
          isSmall
          style={{ padding: 0 }}
        />
      </FlexItem>
    </Flex>
  );
}
