import { useCallback, useEffect, useState } from "react";
import {
  BreadcrumbItem,
  Button,
  EmptyState,
  EmptyStateBody,
  EmptyStateIcon,
  Flex,
  FlexItem,
  Tab,
  Tabs,
  TabTitleText,
  Title,
} from "@patternfly/react-core";
import {
  PlusCircleIcon,
  RepositoryIcon,
  UsersIcon,
} from "@patternfly/react-icons";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  getProjet,
  createVariante,
  updateVariante,
  deleteVariante,
  updateProjet,
  getVarianteParDefaut,
} from "services/api";
import { IProjet, INouvelleVariante } from "types";
import useModal from "hooks/useModal";
import NouvelleVarianteModal from "./NouvelleVarianteModal";
import VarianteTabTitle from "./VarianteTabTitle";
import { sortByDate } from "services/sort";
import VarianteInitiale from "./VarianteInitiale";
import TextEditable from "./TextEditable";
import { showError } from "alerts/alertsActions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import Variante from "./Variante";
import hash from "object-hash";
import MainPage from "layout/MainPage";
import BreadcrumbWithNav from "components/BreadcrumbWithNav";
import { useAuth } from "auth/authContext";
import PartagerProjetModal from "./PartagerProjetModal";

function NouvelleVarianteButton({ ...props }: { [x: string]: any }) {
  return (
    <Button variant="tertiary" {...props}>
      <PlusCircleIcon /> Ajouter une variante
    </Button>
  );
}

export default function PageProjet() {
  const { currentUser } = useAuth();
  const { projet_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [projet, setProjet] = useState<IProjet | null>(null);
  const [varianteInitiale, setVarianteInitiale] =
    useState<INouvelleVariante>(VarianteInitiale);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const { isOpen, show, hide } = useModal(false);
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const variante_id = searchParams.get("variante_id");

  const getProjetCallback = useCallback(
    (projet_id: string) => {
      return getProjet(projet_id).then((response) => {
        const p = response.data;
        const variantes = sortByDate(p.variantes, "creeLe");
        p.variantes = variantes;
        setProjet(p);
        if (variantes.length > 0) {
          if (variante_id === null) {
            setSearchParams({ variante_id: variantes[0].id });
          } else {
            const varianteIndex = variantes.findIndex(
              (v) => v.id === variante_id
            );
            if (varianteIndex === -1) {
              setSearchParams({ variante_id: variantes[0].id });
            } else {
              setActiveTabKey(varianteIndex);
            }
          }
        }
        return response;
      });
    },
    [variante_id, setSearchParams]
  );

  useEffect(() => {
    if (projet_id !== undefined) {
      getProjetCallback(projet_id)
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  }, [variante_id, getProjetCallback, projet_id, setIsLoading]);

  const getVarianteParDefautCallback = useCallback(() => {
    getVarianteParDefaut()
      .then((v) => {
        setVarianteInitiale({
          ...VarianteInitiale,
          ...v,
        });
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    getVarianteParDefautCallback();
  }, [getVarianteParDefautCallback]);

  if (isLoading) {
    return (
      <MainPage
        title="Projet"
        isLoading
        breadcrumb={
          <BreadcrumbWithNav>
            <BreadcrumbItem>
              <Link to="/projets">Projets</Link>
            </BreadcrumbItem>
          </BreadcrumbWithNav>
        }
      ></MainPage>
    );
  }

  if (projet === null) return null;

  const variantes = sortByDate(projet.variantes, "creeLe");

  return (
    <MainPage
      title="Projet"
      breadcrumb={
        <BreadcrumbWithNav>
          <BreadcrumbItem>
            <Link to="/projets">Projets</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <TextEditable
              text={projet.nom}
              onSubmit={(nom) => {
                updateProjet({ ...projet, nom })
                  .then(() => {
                    setProjet({ ...projet, nom });
                  })
                  .catch(() => {
                    dispatch(
                      showError("Impossible de mettre à jour ce projet")
                    );
                  });
              }}
            >
              <span>{projet.nom}</span>
            </TextEditable>
          </BreadcrumbItem>
        </BreadcrumbWithNav>
      }
      topActions={
        <>
          <NouvelleVarianteModal
            isOpen={isOpen}
            hide={hide}
            onSubmit={({ nom }) => {
              createVariante({
                ...varianteInitiale,
                nom,
                projet: projet.id,
              }).then((response) => {
                setSearchParams({ variante_id: response.data.id });
                hide();
              });
            }}
          />
          <Flex>
            <FlexItem>
              <NouvelleVarianteButton onClick={show} />
            </FlexItem>
            {currentUser?.email === projet.createur.email && (
              <FlexItem>
                <PartagerProjetModal projet={projet}>
                  {(s) => (
                    <Button variant="tertiary" onClick={s}>
                      <UsersIcon /> Partager ce projet
                    </Button>
                  )}
                </PartagerProjetModal>
              </FlexItem>
            )}
          </Flex>
        </>
      }
    >
      {variantes.length === 0 ? (
        <EmptyState>
          <EmptyStateIcon icon={RepositoryIcon} />
          <Title headingLevel="h4" size="lg">
            Pas de variante
          </Title>
          <EmptyStateBody>
            Ce projet n'a pas de variante. Créer une variante pour commencer.
          </EmptyStateBody>
          <div className="mt-5">
            <NouvelleVarianteButton onClick={show} />
          </div>
        </EmptyState>
      ) : (
        <Tabs
          activeKey={activeTabKey}
          onSelect={(event, tabIndex) => {
            if (tabIndex !== undefined) {
              const index = parseInt(tabIndex as string, 10);
              setActiveTabKey(index);
              if (variantes[index].id !== variante_id) {
                setSearchParams({ variante_id: variantes[index].id });
              }
            }
          }}
          isBox
        >
          {variantes.map((v, i) => (
            <Tab
              key={i}
              eventKey={i}
              title={
                <TabTitleText>
                  <VarianteTabTitle
                    variante={v}
                    onChange={(nouvelleVariante) => {
                      updateVariante(nouvelleVariante).then(() => {
                        return getProjetCallback(projet.id);
                      });
                    }}
                    onDuplicate={(varianteDupliquee) => {
                      createVariante({
                        ...varianteDupliquee,
                        nom: `copie de ${varianteDupliquee.nom}`,
                      }).then((r1) => {
                        setSearchParams({ variante_id: r1.data.id });
                      });
                    }}
                    onDelete={(varianteASupprimer) => {
                      deleteVariante(varianteASupprimer).then(() => {
                        setSearchParams();
                      });
                    }}
                  />
                </TabTitleText>
              }
            >
              {activeTabKey === i && (
                <Variante
                  key={hash(v)}
                  variante={v}
                  updateVariante={(nouvelleVariante) => {
                    updateVariante(nouvelleVariante)
                      .then(() => {
                        return getProjetCallback(projet.id);
                      })
                      .catch(() => {
                        dispatch(
                          showError(
                            "Nous sommes désolés, nous ne pouvons pas mettre à jour cette variante."
                          )
                        );
                      });
                  }}
                />
              )}
            </Tab>
          ))}
        </Tabs>
      )}
    </MainPage>
  );
}
