import { chaineVersMasqueEuro } from "components/form/transform";
import { LigneAmortissement } from "types";
import { ajout_sous_totaux_tableau_amortissement } from "variante/amortissement";

interface TableauAmortissementProps {
  tableau: LigneAmortissement[];
}

export default function TableauAmortissement({
  tableau,
}: TableauAmortissementProps) {
  const tableauAvecSousTotaux =
    ajout_sous_totaux_tableau_amortissement(tableau);
  return (
    <div style={{ overflow: "auto", height: "80vh" }}>
      <table className="pf-c-table pf-m-grid-md pf-m-compact">
        <thead>
          <tr>
            <th colSpan={100}>
              <span className="text-base leading-6 font-medium text-gray-900">
                Tableau amortissement
              </span>
            </th>
          </tr>
          <tr>
            <th
              style={{
                top: 0,
                position: "sticky",
                backgroundColor: "white",
                zIndex: 10,
              }}
            >
              Numéro
            </th>
            <th
              style={{
                top: 0,
                position: "sticky",
                backgroundColor: "white",
                zIndex: 10,
              }}
              className="text-center"
            >
              Date
            </th>
            <th
              style={{
                top: 0,
                position: "sticky",
                backgroundColor: "white",
                zIndex: 10,
              }}
              className="text-right"
            >
              Restant dû
            </th>
            <th
              style={{
                top: 0,
                position: "sticky",
                backgroundColor: "white",
                zIndex: 10,
              }}
              className="text-right"
            >
              Intérêts
            </th>
            <th
              style={{
                top: 0,
                position: "sticky",
                backgroundColor: "white",
                zIndex: 10,
              }}
              className="text-right"
            >
              Principal
            </th>
            <th
              style={{
                top: 0,
                position: "sticky",
                backgroundColor: "white",
                zIndex: 10,
              }}
              className="text-right"
            >
              Assurance
            </th>
            <th
              style={{
                top: 0,
                position: "sticky",
                backgroundColor: "white",
                zIndex: 10,
              }}
              className="text-right"
            >
              Échéance
            </th>
          </tr>
        </thead>
        <tbody>
          {tableauAvecSousTotaux.map((ligne, i) => {
            return (
              <tr
                key={i}
                style={{
                  backgroundColor:
                    ligne.numero_mois === null ? "#e5e7eb" : "#ffffff",
                  zIndex: 0,
                }}
                className="font-mono"
              >
                {ligne.numero_mois === null ? (
                  <td colSpan={3}>{ligne.date}</td>
                ) : (
                  <>
                    <td>{ligne.numero_mois}</td>
                    <td
                      style={{ backgroundColor: "white" }}
                      className="text-center"
                    >
                      {ligne.date}
                    </td>
                    <td className="text-right">
                      {chaineVersMasqueEuro(ligne.capital_restant)}
                    </td>
                  </>
                )}
                <td className="text-right">
                  {chaineVersMasqueEuro(ligne.interets)}
                </td>
                <td className="text-right">
                  {chaineVersMasqueEuro(ligne.principal)}
                </td>
                <td className="text-right">
                  {chaineVersMasqueEuro(ligne.assurance)}
                </td>
                <td className="text-right">
                  {chaineVersMasqueEuro(ligne.echeance)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
