import React from "react";
import { Input } from "components/form";
import * as Yup from "yup";
import { Form, Formik, useFormikContext } from "formik";
import { INouveauProjet } from "types";
import { Button, Modal, ModalVariant } from "@patternfly/react-core";

const SchemaCreerProjet = Yup.object().shape({
  nom: Yup.string()
    .min(2, "Le nom d'un projet est trop court")
    .required("Le nom d'un projet est nécessaire"),
});

function CreerNouveauProjetButton({ ...props }: { [x: string]: any }) {
  const { submitForm } = useFormikContext();
  return (
    <Button {...props} onClick={submitForm}>
      Créer
    </Button>
  );
}

interface NouveauProjetModalProps {
  isOpen: boolean;
  hide: () => void;
  onSubmit: (user: INouveauProjet) => void;
}

export default function NouveauProjetModal({
  isOpen,
  hide,
  onSubmit,
}: NouveauProjetModalProps) {
  return (
    <Formik
      initialValues={{ nom: "" }}
      validationSchema={SchemaCreerProjet}
      onSubmit={onSubmit}
    >
      <Modal
        variant={ModalVariant.medium}
        title="Créer un nouveau projet"
        isOpen={isOpen}
        onClose={hide}
        actions={[
          <CreerNouveauProjetButton key="confirm" variant="primary">
            Créer
          </CreerNouveauProjetButton>,
          <Button key="cancel" variant="link" onClick={hide}>
            Annuler
          </Button>,
        ]}
      >
        <Form id="nouveau_projet_form" className="pf-c-form">
          <Input
            id="nouveau_projet_form__nom"
            data-testid="nouveau_projet_form__nom"
            label="Nom"
            name="nom"
            isRequired
            autoFocus
          />
        </Form>
      </Modal>
    </Formik>
  );
}
