import {
  chaineVersMasqueEuro,
  chaineVersMasquePourcentage,
  chaineVersMasqueAnnees,
  chaineVersMasqueMois,
} from "components/form/transform";
import { IConclusion } from "types";
import { Grid, GridItem } from "@patternfly/react-core";
import QuestionMarkHelper from "components/QuestionMarkHelper";

interface RecapitulatifProps {
  conclusion: IConclusion;
}

export default function Recapitulatif({ conclusion }: RecapitulatifProps) {
  return (
    <Grid sm={12} md={6} xl={3}>
      <GridItem>
        <table className="pf-c-table pf-m-grid-md pf-m-compact">
          <thead>
            <tr>
              <th colSpan={100}>
                <span className="text-base leading-6 font-medium text-gray-900">
                  Statistiques
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>
                  Indice au 100K
                  <span className="ml-2">
                    <QuestionMarkHelper content="Combien de cashflow pour 100 000€ investi" />
                  </span>
                </b>
              </td>
              <td className="font-mono">
                {conclusion.statistiques.indice_au_100}
              </td>
            </tr>
            <tr>
              <td>Renta brute</td>
              <td className="font-mono">
                {chaineVersMasquePourcentage(
                  conclusion.statistiques.rentabilite_brute
                )}
              </td>
            </tr>
            <tr>
              <td>Renta nette</td>
              <td className="font-mono">
                {chaineVersMasquePourcentage(
                  conclusion.statistiques.rentabilite_nette
                )}
              </td>
            </tr>
            <tr>
              <td>Renta cash flow / apport</td>
              <td className="font-mono">
                {chaineVersMasquePourcentage(
                  conclusion.statistiques.renta_cash_flow_sur_apport
                )}
              </td>
            </tr>
            <tr>
              <td>
                <b>% cash flow</b>
              </td>
              <td className="font-mono">
                {chaineVersMasquePourcentage(
                  conclusion.statistiques.pourcentage_cash_flow
                )}
              </td>
            </tr>
            <tr>
              <td>Rapport crédit / loyers</td>
              <td className="font-mono">
                {chaineVersMasquePourcentage(
                  conclusion.statistiques.rapport_credit_sur_loyer
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </GridItem>
      <GridItem>
        <table className="pf-c-table pf-m-grid-md pf-m-compact">
          <thead>
            <tr>
              <th colSpan={100}>
                <span className="text-base leading-6 font-medium text-gray-900">
                  Acquisition
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Prix achat FAI</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(conclusion.acquisition.prix_achat_FAI)}
              </td>
            </tr>
            <tr>
              <td>Frais de notaire</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(conclusion.acquisition.frais_de_notaire)}
              </td>
            </tr>
            <tr>
              <td>Frais de garanties</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(
                  conclusion.acquisition.frais_de_garanties
                )}
              </td>
            </tr>
            <tr>
              <td>Travaux</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(conclusion.acquisition.travaux)}
              </td>
            </tr>
            <tr>
              <td>
                <b>Total projet</b>
              </td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(conclusion.acquisition.total_projet)}
              </td>
            </tr>
            <tr>
              <td>Apport</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(conclusion.acquisition.apport)}
              </td>
            </tr>
            <tr>
              <td>
                <b>Total à financer</b>
              </td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(conclusion.acquisition.total_a_financer)}
              </td>
            </tr>
            <tr>
              <td>Prix m² achat TTC</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(conclusion.acquisition.prix_m2_achat_TTC)}
              </td>
            </tr>
          </tbody>
        </table>
      </GridItem>
      <GridItem>
        <table className="pf-c-table pf-m-grid-md pf-m-compact">
          <thead>
            <tr>
              <th colSpan={100}>
                <span className="text-base leading-6 font-medium text-gray-900">
                  Caractéristiques du crédit
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Montant Emprunté</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(
                  conclusion.amortissement.montant_emprunte
                )}
              </td>
            </tr>
            <tr>
              <td>Taux intérêt annuel</td>
              <td className="text-right font-mono">
                {chaineVersMasquePourcentage(
                  conclusion.amortissement.taux_interet
                )}
              </td>
            </tr>
            <tr>
              <td>Durée du crédit</td>
              <td className="text-right font-mono">
                {chaineVersMasqueAnnees(conclusion.amortissement.duree_pret)}
              </td>
            </tr>
            <tr>
              <td>Durée du différé</td>
              <td className="text-right font-mono">
                {chaineVersMasqueMois(conclusion.amortissement.differe)}
              </td>
            </tr>
            <tr>
              <td>Taux assurance</td>
              <td className="text-right font-mono">
                {chaineVersMasquePourcentage(
                  conclusion.amortissement.taux_assurance
                )}
              </td>
            </tr>
            <tr>
              <td>Date déblocage</td>
              <td className="text-right font-mono">
                {conclusion.amortissement.date_deblocage}
              </td>
            </tr>
          </tbody>
        </table>
      </GridItem>
      <GridItem>
        <table className="pf-c-table pf-m-grid-md pf-m-compact">
          <thead>
            <tr>
              <th colSpan={100}>
                <span className="text-base leading-6 font-medium text-gray-900">
                  Calcul crédit
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Mensualité
                <span className="ml-2">
                  <QuestionMarkHelper content="Mensualité hors assurance" />
                </span>
              </td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(
                  conclusion.amortissement.montant_mensualite
                )}
              </td>
            </tr>
            <tr>
              <td>Montant assurance</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(
                  conclusion.amortissement.montant_assurance
                )}
              </td>
            </tr>
            <tr>
              <td>Coût total du crédit</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(
                  conclusion.amortissement.cout_total_credit
                )}
              </td>
            </tr>
            <tr>
              <td>Coût des intérêts</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(conclusion.amortissement.cout_interet)}
              </td>
            </tr>
            <tr>
              <td>Coût de l'assurance</td>
              <td className="text-right font-mono">
                {chaineVersMasqueEuro(conclusion.amortissement.cout_assurance)}
              </td>
            </tr>
          </tbody>
        </table>
      </GridItem>
    </Grid>
  );
}
