import { PageSection, PageSectionVariants } from "@patternfly/react-core";
import { useState } from "react";
import { IVariante } from "types";
import { recalculer } from "variante/variante";
import Conclusion from "../conclusion/Conclusion";
import VarianteForm from "./VarianteForm";

interface VarianteProps {
  variante: IVariante;
  updateVariante: (v: IVariante) => void;
}

export default function Variante({ variante, updateVariante }: VarianteProps) {
  const [innerVariante, setInnerVariante] = useState<IVariante>(variante);
  return (
    <>
      <PageSection
        variant={PageSectionVariants.light}
        style={{ paddingTop: "0.2rem" }}
      >
        <VarianteForm
          key={innerVariante.id}
          variante={innerVariante}
          onSubmit={(v) => {
            const nouvelleVariante = recalculer(innerVariante, {
              ...innerVariante,
              ...v,
            });
            setInnerVariante(nouvelleVariante);
            updateVariante(nouvelleVariante);
          }}
        />
      </PageSection>
      {/* <PageSection variant={PageSectionVariants.light}>
        <Button
          onClick={() => {
            navigate(
              `/conclusion/${innerVariante.projet}/variantes/${innerVariante.id}`
            );
          }}
        >
          Imprimer
        </Button>
      </PageSection> */}
      <PageSection variant={PageSectionVariants.light}>
        {<Conclusion variante={innerVariante} />}
      </PageSection>
    </>
  );
}
