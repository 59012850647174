import { useCallback, useEffect, useState } from "react";
import {
  BreadcrumbItem,
  Button,
  ButtonVariant,
  Card,
  CardBody,
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  EmptyState,
  EmptyStateBody,
  EmptyStateIcon,
  Grid,
  GridItem,
  InputGroup,
  TextInput,
  Title,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
} from "@patternfly/react-core";
import { FolderIcon, SearchIcon } from "@patternfly/react-icons";
import { Link, useNavigate } from "react-router-dom";
import { createProjet, deleteProjet, getProjets } from "services/api";
import { IProjet } from "types";
import useModal from "hooks/useModal";
import NouveauProjetModal from "./NouveauProjetModal";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { compareDesc, formatDistanceToNow, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import MainPage from "layout/MainPage";
import TrashButtonIcon from "components/TrashButtonIcon";
import BreadcrumbWithNav from "components/BreadcrumbWithNav";

export default function PageProjets() {
  const navigate = useNavigate();
  const [projets, setProjets] = useState<IProjet[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, show, hide } = useModal(false);
  const [textSearch, setTextSearch] = useState("");

  const getProjetsCallback = useCallback(() => {
    setIsLoading(true);
    getProjets()
      .then((response) => {
        setProjets(response.data.results);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getProjetsCallback();
  }, [getProjetsCallback]);

  const projetsFiltres = projets.filter(
    (p) => p.nom.toLowerCase().indexOf(textSearch.toLocaleLowerCase()) !== -1
  );

  return (
    <MainPage
      title="Projets"
      description="Liste de vos projets"
      isLoading={isLoading}
      breadcrumb={
        <BreadcrumbWithNav>
          <BreadcrumbItem>
            <Link to="/projets">Projets</Link>
          </BreadcrumbItem>
        </BreadcrumbWithNav>
      }
    >
      <NouveauProjetModal
        isOpen={isOpen}
        hide={hide}
        onSubmit={(projet) => {
          createProjet(projet).then((response) => {
            hide();
            setProjets([response.data, ...projets]);
          });
        }}
      />

      {projets.length === 0 ? (
        <Card>
          <CardBody>
            <EmptyState>
              <EmptyStateIcon icon={FolderIcon} />
              <Title headingLevel="h4" size="lg">
                Pas de projet
              </Title>
              <EmptyStateBody>
                Vous n'avez aucun projet enregistré sur ce compte.
              </EmptyStateBody>
              <Button variant="primary" onClick={show}>
                Créer un projet
              </Button>
            </EmptyState>
          </CardBody>
        </Card>
      ) : (
        <>
          <Toolbar id="chercher-projets" className="pf-m-page-insets">
            <ToolbarContent>
              <ToolbarItem>
                <InputGroup>
                  <TextInput
                    id="search"
                    name="search"
                    type="search"
                    aria-label="rechercher un projet"
                    onChange={(s) => setTextSearch(s)}
                    value={textSearch}
                    placeholder="Rechercher un projet"
                  />
                  <Button
                    variant={ButtonVariant.control}
                    aria-label="bouton rechercher un projet"
                  >
                    <SearchIcon />
                  </Button>
                </InputGroup>
              </ToolbarItem>
              <ToolbarItem variant="separator" />
              <ToolbarItem>
                <Button variant="primary" onClick={show}>
                  Créer un nouveau projet
                </Button>
              </ToolbarItem>
            </ToolbarContent>
          </Toolbar>
          {projetsFiltres.length === 0 && textSearch && (
            <Card>
              <CardBody>
                <EmptyState>
                  <EmptyStateIcon icon={SearchIcon} />
                  <Title headingLevel="h4" size="lg">
                    Pas de résultats
                  </Title>
                  <EmptyStateBody>
                    Vous n'avez aucun projet correspondant à cette recherche.
                    Changez votre recherche.
                  </EmptyStateBody>
                </EmptyState>
              </CardBody>
            </Card>
          )}
          <DataList
            aria-label="data list"
            onSelectDataListItem={(projectId) => {
              navigate(`/projets/${projectId}`);
            }}
          >
            {projetsFiltres
              .sort((p1, p2) =>
                compareDesc(parseISO(p1.creeLe), parseISO(p2.creeLe))
              )
              .map((projet) => (
                <DataListItem id={projet.id} key={projet.id}>
                  <DataListItemRow>
                    <DataListItemCells
                      dataListCells={[
                        <DataListCell key="primary content">
                          <Grid hasGutter>
                            <GridItem
                              span={6}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {projet.nom}
                            </GridItem>
                            <GridItem
                              span={3}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {`créé il y a ${formatDistanceToNow(
                                parseISO(projet.creeLe),
                                { locale: fr }
                              )}`}
                            </GridItem>
                            <GridItem span={3} style={{ textAlign: "right" }}>
                              {!projet.partage && (
                                <ConfirmDeleteModal
                                  titre={`Etes vous sur de vouloir supprimer ce projet ?`}
                                  message={`Vous êtes sur le point de supprimer le projet ${projet.nom}. Assurez vous de ne plus avoir besoin de ce projet avant de cliquer sur supprimer.`}
                                  onOk={() => {
                                    deleteProjet(projet).then(
                                      getProjetsCallback
                                    );
                                  }}
                                >
                                  {(openModal) => (
                                    <TrashButtonIcon onClick={openModal} />
                                  )}
                                </ConfirmDeleteModal>
                              )}
                            </GridItem>
                          </Grid>
                        </DataListCell>,
                      ]}
                    />
                  </DataListItemRow>
                </DataListItem>
              ))}
          </DataList>
        </>
      )}
    </MainPage>
  );
}
