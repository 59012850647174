import { useEffect, useState } from "react";
import {
  Page,
  PageSection,
  PageSectionVariants,
  Title,
  TitleSizes,
} from "@patternfly/react-core";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { getDonneesFiscales, getProjet } from "services/api";
import { IConclusion } from "types";
import NotAuthenticatedLoadingPage from "layout/NotAuthenticatedLoadingPage";
import { useAuth } from "auth/authContext";
import { calculer_conclusion } from "variante/conclusion";
import Recapitulatif from "./Recapitulatif";
import TableauTresorerie from "./TableauTresorerie";
import TableauAmortissement from "./TableauAmortissement";
import TableauRecapRevente from "./TableauRecapRevente";

export default function PageConclusion() {
  const { projet_id, variante_id } = useParams();
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [conclusion, setConclusion] = useState<IConclusion | null>(null);

  useEffect(() => {
    if (projet_id) {
      Promise.all([getProjet(projet_id), getDonneesFiscales()])
        .then((response) => {
          const variantes = response[0].data.variantes;
          const donneesFiscales = response[1].data.results;
          if (variantes.length > 0) {
            const v = variantes.find((v) => v.id === variante_id);
            if (v) {
              setConclusion(calculer_conclusion(v, donneesFiscales));
            }
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [projet_id, variante_id]);

  if (isLoading) {
    return <NotAuthenticatedLoadingPage />;
  }

  if (currentUser === null) {
    return <Navigate to="/se_connecter" state={{ from: location }} replace />;
  }

  if (conclusion === null) return null;

  return (
    <Page header={null} sidebar={null}>
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h1" size={TitleSizes["xl"]}>
          Récapitulatif
        </Title>
        <Recapitulatif conclusion={conclusion} />
      </PageSection>
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h1" size={TitleSizes["xl"]}>
          Bilan trésorerie
        </Title>
        <TableauTresorerie conclusion={conclusion} />
      </PageSection>
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h1" size={TitleSizes["xl"]}>
          Tableau d'amortissement
        </Title>
        <TableauAmortissement tableau={conclusion.amortissement.tableau} />
      </PageSection>
      <PageSection variant={PageSectionVariants.light}>
        <Title headingLevel="h1" size={TitleSizes["xl"]}>
          Tableau récapitulatif à la revente
        </Title>
        <TableauRecapRevente revente={conclusion.revente} />
      </PageSection>
    </Page>
  );
}
