import { round } from "lodash";

export function calcul_impot(
  revenu_net_imposable: number,
  quotient_familial: number
) {
  const revenu_pondere = round(revenu_net_imposable / quotient_familial);
  const tranche1 = 10_084;
  const taux_tranche1 = 0;
  if (revenu_pondere <= tranche1) {
    return round(revenu_pondere * taux_tranche1 * quotient_familial);
  }
  const tranche2 = 25_710;
  const taux_tranche2 = 0.11;
  if (revenu_pondere <= tranche2) {
    return round(
      (tranche1 * taux_tranche1 +
        (revenu_pondere - tranche1 - 1) * taux_tranche2) *
        quotient_familial
    );
  }
  const tranche3 = 73_516;
  const taux_tranche3 = 0.3;
  if (revenu_pondere <= tranche3) {
    return round(
      (tranche1 * taux_tranche1 +
        (tranche2 - tranche1 - 1) * taux_tranche2 +
        (revenu_pondere - tranche2 - 1) * taux_tranche3) *
        quotient_familial
    );
  }
  const tranche4 = 158_122;
  const taux_tranche4 = 0.41;
  if (revenu_pondere <= tranche4) {
    return round(
      (tranche1 * taux_tranche1 +
        (tranche2 - tranche1 - 1) * taux_tranche2 +
        (tranche3 - tranche2 - 1) * taux_tranche3 +
        (revenu_pondere - tranche3 - 1) * taux_tranche4) *
        quotient_familial
    );
  }
  const taux_tranche5 = 0.45;
  return round(
    (tranche1 * taux_tranche1 +
      (tranche2 - tranche1 - 1) * taux_tranche2 +
      (tranche3 - tranche2 - 1) * taux_tranche3 +
      (tranche4 - tranche3 - 1) * taux_tranche4 +
      (revenu_pondere - tranche4 - 1) * taux_tranche5) *
      quotient_familial
  );
}

interface IAbattements {
  [annee: number]: [number, number];
}

export function calcul_impot_sur_plus_value_particulier(
  montant: number,
  annee_de_detention: number
) {
  const abattements: IAbattements = {
    1: [0.0, 0.0],
    2: [0.0, 0.0],
    3: [0.0, 0.0],
    4: [0.0, 0.0],
    5: [0.0, 0.0],
    6: [6.0, 1.65],
    7: [12.0, 3.3],
    8: [18.0, 4.95],
    9: [24.0, 6.6],
    10: [30.0, 8.25],
    11: [36.0, 9.9],
    12: [42.0, 11.55],
    13: [48.0, 13.2],
    14: [54.0, 14.85],
    15: [60.0, 16.5],
    16: [66.0, 18.15],
    17: [72.0, 19.8],
    18: [78.0, 21.45],
    19: [84.0, 23.1],
    20: [90.0, 24.75],
    21: [96.0, 26.4],
    22: [100.0, 28.0],
    23: [100.0, 37.0],
    24: [100.0, 46.0],
    25: [100.0, 55.0],
    26: [100.0, 64.0],
    27: [100.0, 73.0],
    28: [100.0, 82.0],
    29: [100.0, 91.0],
    30: [100.0, 100.0],
  };
  const pourcentage_import_revenu = 19;
  const pourcentage_prelevement_sociaux = 17.2;
  if (annee_de_detention in abattements) {
    const abattement = abattements[annee_de_detention];
    const impots = round(
      (((montant * (100 - abattement[0])) / 100) * pourcentage_import_revenu) /
        100 +
        (((montant * (100 - abattement[1])) / 100) *
          pourcentage_prelevement_sociaux) /
          100,
      2
    );
    if (impots > 0) {
      return impots;
    }
  }
  return 0;
}

export function calcul_impot_sur_societe(montant: number) {
  const abattement_IS = 38120;
  const pourcentage_IS_sur_abattement = 15;
  const pourcentage_IS_normal_apres_2022 = 25;
  return montant < 0
    ? 0
    : montant < abattement_IS
    ? (montant * pourcentage_IS_sur_abattement) / 100
    : (abattement_IS * pourcentage_IS_sur_abattement) / 100 +
      ((montant - abattement_IS) * pourcentage_IS_normal_apres_2022) / 100;
}

export function calcul_impot_LMP(montant: number) {
  const pourcentage_PFU = 30;
  return montant < 0 ? 0 : (montant * pourcentage_PFU) / 100;
}
