import { Outlet } from "react-router-dom";
import { Page } from "@patternfly/react-core";
import BackgroundImage from "components/BackgroundImage";
import Alerts from "alerts/Alerts";

export default function NotAuthenticatedPage({ ...props }) {
  return (
    <Page header={null} sidebar={null} {...props}>
      <BackgroundImage />
      <Alerts />
      <Outlet />
    </Page>
  );
}
