import React from "react";
import { Button } from "@patternfly/react-core";
import { Input } from "components/form";
import * as Yup from "yup";
import { Form, Formik } from "formik";

const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Cet email est invalide").required("Obligatoire"),
});

interface ForgetPasswordFormProps {
  onSubmit: ({ email }: { email: string }) => void;
}

export default function ForgetPasswordForm({
  onSubmit,
}: ForgetPasswordFormProps) {
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={ForgetPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty }) => (
        <Form id="forget_password_form" className="pf-c-form">
          <Input
            id="forget_password_form__email"
            data-testid="forget_password_form__email"
            label="Email"
            name="email"
            type="email"
            isRequired
          />
          <Button
            variant="primary"
            type="submit"
            isDisabled={!(isValid && dirty)}
          >
            Retrouver mon mot de passe
          </Button>
        </Form>
      )}
    </Formik>
  );
}
