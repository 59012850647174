import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  PageContextConsumer,
} from "@patternfly/react-core";
import { BarsIcon } from "@patternfly/react-icons";

interface BreadcrumbWithNavProps {
  children: React.ReactNode;
}

export default function BreadcrumbWithNav({
  children,
}: BreadcrumbWithNavProps) {
  return (
    <Breadcrumb>
      <PageContextConsumer>
        {({ onNavToggle, isNavOpen }) => {
          if (isNavOpen) return null;
          return (
            <BreadcrumbItem>
              <Button variant="tertiary" onClick={onNavToggle}>
                <BarsIcon />
              </Button>
            </BreadcrumbItem>
          );
        }}
      </PageContextConsumer>

      {children}
    </Breadcrumb>
  );
}
