import { addMonths, parse, format, getYear } from "date-fns";
import { round, range } from "lodash";
import { IAmortissement, IVariante, LigneAmortissement } from "types";

const date_format = "dd/MM/yyyy";
const amortissement_vide = {
  tableau: [],
  montant_travaux: 0,
  montant_emprunte: 0,
  taux_interet: 0,
  duree_calcul: 0,
  duree_pret: 0,
  differe: 0,
  taux_assurance: 0,
  date_deblocage: format(new Date(), date_format),
  montant_mensualite: 0,
  montant_mensualite_pendant_duree_calcul: 0,
  montant_assurance: 0,
  cout_interet: 0,
  cout_assurance: 0,
  cout_total_credit: 0,
  capital_rembourse: 0,
};

export function calculer_tableau_amortissement(
  variante: IVariante
): IAmortissement {
  const taux_interet = variante["taux_interet"] / 100;
  const taux_assurance = variante["taux_assurance"] / 100;
  const duree_pret = variante["duree_pret"];
  const echeances_par_an = 12;
  const differe_mois = variante["differe"];
  const duree_pret_mois = duree_pret * echeances_par_an + differe_mois;
  const duree_calcul_mois = variante["duree_calcul"] * echeances_par_an;
  const amortissement_mois = duree_pret_mois - differe_mois;
  const prix_achat_FAI = variante["prix_achat_FAI"];
  const frais_de_notaire = variante["frais_de_notaire"];
  const frais_de_garanties = variante["frais_de_garanties"];
  const montant_travaux = variante.travaux.reduce((acc, travail) => {
    acc += travail.montant;
    return acc;
  }, 0);
  const apport = variante["apport_eventuel"];
  const total_projet =
    prix_achat_FAI + frais_de_notaire + frais_de_garanties + montant_travaux;
  const total_a_financer = total_projet - apport;
  if (duree_pret_mois === 0 || taux_interet === 0 || total_a_financer === 0) {
    return amortissement_vide;
  }

  const taux_periodique = Math.pow(taux_interet + 1, 1 / echeances_par_an) - 1;
  const montant_mensualite = round(
    (total_a_financer * taux_periodique) /
      (1 - Math.pow(1 + taux_periodique, -amortissement_mois)),
    2
  );
  const tableau_amortissement: LigneAmortissement[] = [];
  let date = parse(variante["date_deblocage"], date_format, new Date());
  const deblocage: LigneAmortissement = {
    numero_mois: 0,
    date: format(date, date_format),
    capital_restant: total_a_financer,
    interets: 0,
    principal: 0,
    assurance: 0,
    echeance: 0,
    annee: getYear(date),
  };
  tableau_amortissement.push(deblocage);
  let capital_restant = total_a_financer;
  let interets = 0;
  let principal = 0;
  let assurance = round(
    (total_a_financer * taux_assurance) / echeances_par_an,
    2
  );
  let cout_interet = 0;
  let cout_assurance = 0;
  let capital_rembourse = 0;
  let total_pendant_duree_calcul = 0;
  const months = range(1, duree_pret_mois + 1);
  for (let i = 0; i < months.length; i++) {
    const month = months[i];
    date = addMonths(date, 1);
    capital_restant = round(capital_restant - principal, 2);
    interets = round(capital_restant * taux_periodique, 2);
    if (month === duree_pret_mois) {
      principal = capital_restant;
    } else if (month > differe_mois) {
      principal = montant_mensualite - interets;
    } else {
      principal = 0;
    }
    const echeance_sans_assurance = interets + principal;
    const echeance = echeance_sans_assurance + assurance;
    cout_interet += interets;
    cout_assurance += assurance;
    capital_rembourse += principal;
    tableau_amortissement.push({
      numero_mois: month,
      date: format(date, date_format),
      capital_restant: capital_restant,
      interets: interets,
      principal: round(principal, 2),
      assurance: assurance,
      echeance: round(echeance, 2),
      annee: getYear(date),
    });
    if (month <= duree_calcul_mois) {
      total_pendant_duree_calcul += round(echeance_sans_assurance, 2);
    }
  }
  const montant_mensualite_pendant_duree_calcul =
    duree_calcul_mois === 0
      ? montant_mensualite
      : total_pendant_duree_calcul / duree_calcul_mois;
  return {
    montant_travaux: round(montant_travaux, 2),
    tableau: tableau_amortissement,
    montant_emprunte: total_a_financer,
    taux_interet: variante["taux_interet"],
    duree_calcul: variante["duree_calcul"],
    duree_pret: variante["duree_pret"],
    differe: variante["differe"],
    taux_assurance: variante["taux_assurance"],
    date_deblocage: variante["date_deblocage"],
    montant_mensualite: montant_mensualite,
    montant_mensualite_pendant_duree_calcul: round(
      montant_mensualite_pendant_duree_calcul,
      2
    ),
    montant_assurance: round(assurance, 2),
    cout_interet: round(cout_interet, 2),
    cout_assurance: round(cout_assurance, 2),
    cout_total_credit: round(cout_interet + cout_assurance, 2),
    capital_rembourse: round(capital_rembourse, 2),
  };
}

export function ajout_sous_totaux_tableau_amortissement(
  tableau_amortissement: LigneAmortissement[]
) {
  const nouveauTableauAmortissement: LigneAmortissement[] = [];
  let totalInteret = 0;
  let sousTotalInteret = 0;
  let totalPrincipal = 0;
  let sousTotalPrincipal = 0;
  let totalAssurance = 0;
  let sousTotalAssurance = 0;
  let totalEcheance = 0;
  let sousTotalEcheance = 0;
  let derniereDate = new Date();
  for (let i = 0; i < tableau_amortissement.length; i++) {
    const ligneAmortissement = tableau_amortissement[i];
    const date = parse(ligneAmortissement.date, date_format, new Date());
    derniereDate = date;
    nouveauTableauAmortissement.push(ligneAmortissement);
    totalInteret += ligneAmortissement.interets;
    sousTotalInteret += ligneAmortissement.interets;
    totalPrincipal += ligneAmortissement.principal;
    sousTotalPrincipal += ligneAmortissement.principal;
    totalAssurance += ligneAmortissement.assurance;
    sousTotalAssurance += ligneAmortissement.assurance;
    totalEcheance += ligneAmortissement.echeance;
    sousTotalEcheance += ligneAmortissement.echeance;
    if (
      (i !== 0 && date.getMonth() === 11) ||
      i === tableau_amortissement.length - 1
    ) {
      nouveauTableauAmortissement.push({
        numero_mois: null,
        date: `Total ${getYear(date)}`,
        capital_restant: 0,
        interets: round(sousTotalInteret, 2),
        principal: round(sousTotalPrincipal, 2),
        assurance: round(sousTotalAssurance, 2),
        echeance: round(sousTotalEcheance, 2),
        annee: getYear(date),
      });
      sousTotalInteret = 0;
      sousTotalPrincipal = 0;
      sousTotalAssurance = 0;
      sousTotalEcheance = 0;
    }
  }
  nouveauTableauAmortissement.push({
    numero_mois: null,
    date: "Total",
    capital_restant: 0,
    interets: round(totalInteret, 2),
    principal: round(totalPrincipal, 2),
    assurance: round(totalAssurance, 2),
    echeance: round(totalEcheance, 2),
    annee: getYear(derniereDate),
  });
  return nouveauTableauAmortissement;
}
