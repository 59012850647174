import { Bullseye, Page } from "@patternfly/react-core";
import LoadingState from "components/LoadingState";

export default function NotAuthenticatedLoadingPage() {
  return (
    <Page header={null} sidebar={null}>
      <Bullseye>
        <LoadingState />
      </Bullseye>
    </Page>
  );
}
