import React, { useState } from "react";
import {
  Link,
  useLocation,
  useResolvedPath,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownPosition,
  Nav,
  NavItem,
  Page,
  PageSidebar,
  NavGroup,
  Masthead,
  MastheadToggle,
  Button,
  MastheadMain,
  MastheadBrand,
  MastheadContent,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
  DropdownItem,
  DropdownSeparator,
  PageContextConsumer,
  PageSection,
} from "@patternfly/react-core";
import { BarsIcon, HelpIcon, UserIcon } from "@patternfly/react-icons";
import { useAuth, AuthContextProps } from "auth/authContext";
import BackgroundImage from "components/BackgroundImage";
import Alerts from "alerts/Alerts";
import Messages from "messages/Messages";

interface MenuDropdownProps {
  title: React.ReactNode;
  position: DropdownPosition;
  dropdownItems: any[];
}

export function MenuDropdown({
  title,
  position,
  dropdownItems,
}: MenuDropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <Dropdown
      isPlain
      position={position}
      onSelect={() => setIsDropdownOpen(!isDropdownOpen)}
      isOpen={isDropdownOpen}
      toggle={
        <DropdownToggle onToggle={setIsDropdownOpen}>{title}</DropdownToggle>
      }
      dropdownItems={dropdownItems}
    />
  );
}

interface AppNavItemProps {
  children: React.ReactNode;
  to: string;
  exact?: boolean;
}

export function AppNavItem({ children, to, ...props }: AppNavItemProps) {
  const location = useLocation();
  const path = useResolvedPath(to);
  const isActive = location.pathname.startsWith(path.pathname);
  return (
    <NavItem isActive={isActive}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </NavItem>
  );
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Header({
  toggleSidebarVisibility,
}: {
  toggleSidebarVisibility: () => void;
}) {
  const { currentUser, logout }: AuthContextProps = useAuth();

  const navigate = useNavigate();
  if (currentUser === null) return null;

  const dropdownUserLinks = [
    <DropdownItem
      key="dropdown_user_settings"
      component="button"
      onClick={() => navigate("/mon_compte")}
    >
      Mon compte
    </DropdownItem>,
    <DropdownSeparator key="dropdown_user_separator" />,
    <DropdownItem
      key="dropdown_user_logout"
      component="button"
      onClick={() => {
        logout();
      }}
    >
      Déconnexion
    </DropdownItem>,
  ];

  return (
    <Masthead id="masthead">
      <MastheadToggle>
        <Button
          variant="plain"
          onClick={toggleSidebarVisibility}
          aria-label="Global navigation"
        >
          <BarsIcon />
        </Button>
      </MastheadToggle>
      <MastheadMain>
        <MastheadBrand onClick={() => navigate("/")}>BOPIL</MastheadBrand>
      </MastheadMain>
      <MastheadContent>
        <Toolbar id="toolbar" isFullHeight isStatic>
          <ToolbarContent>
            <ToolbarGroup
              variant="icon-button-group"
              alignment={{ default: "alignRight" }}
              spacer={{ default: "spacerNone", md: "spacerMd" }}
            >
              <ToolbarItem>
                <Button
                  component="a"
                  variant="link"
                  href="https://groupe-locus.notion.site/Tutoriel-BOPIL-f976832682e84956b70bfbaad2c3c0a5?pvs=4"
                  target="top"
                  aria-label="Tutoriel page"
                  style={{ color: "white" }}
                >
                  <HelpIcon />
                </Button>
              </ToolbarItem>
              <ToolbarItem
                visibility={{
                  default: "visible",
                  md: "hidden",
                  lg: "hidden",
                  xl: "hidden",
                  "2xl": "hidden",
                }}
              >
                <MenuDropdown
                  position={DropdownPosition.right}
                  title={<UserIcon />}
                  dropdownItems={dropdownUserLinks}
                />
              </ToolbarItem>
              <ToolbarItem visibility={{ default: "hidden", md: "visible" }}>
                <MenuDropdown
                  position={DropdownPosition.right}
                  title={
                    <span>
                      <UserIcon className="mr-md" />
                      {currentUser.email}
                    </span>
                  }
                  dropdownItems={dropdownUserLinks}
                />
              </ToolbarItem>
            </ToolbarGroup>
          </ToolbarContent>
        </Toolbar>
      </MastheadContent>
    </Masthead>
  );
}

function Sidebar() {
  const { currentUser, logout }: AuthContextProps = useAuth();
  if (currentUser === null) return null;
  const PageNav = (
    <Nav aria-label="Nav" theme="dark">
      <PageContextConsumer>
        {({ onNavToggle }) => {
          return (
            <section className="pf-c-nav__section">
              <h2
                className="pf-c-nav__section-title border-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  onClick={onNavToggle}
                  style={{
                    color: "white",
                    border: "none",
                    marginRight: "0.5em",
                    backgroundColor: "transparent",
                    fontSize: "2em",
                  }}
                >
                  <BarsIcon />
                </button>

                <span style={{ fontSize: "1.8em" }}>BOPIL</span>
              </h2>
            </section>
          );
        }}
      </PageContextConsumer>
      <NavGroup title="Bopil">
        <AppNavItem to="/projets" exact={false}>
          Projets
        </AppNavItem>
        <NavItem>
          <a
            href="https://www.notion.so/Tutoriel-BOPIL-f976832682e84956b70bfbaad2c3c0a5"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tutoriel
          </a>
        </NavItem>
        <NavItem>
          <a href="mailto:support@yakademie.com">Contact</a>
        </NavItem>
      </NavGroup>
      <NavGroup title={currentUser.email}>
        <AppNavItem to="/mon_compte">Mon compte</AppNavItem>
        <NavItem onClick={() => logout()}>Déconnexion</NavItem>
      </NavGroup>
    </Nav>
  );
  return <PageSidebar nav={PageNav} theme="dark" />;
}

interface AuthenticatedPageProps {
  breadcrumb?: React.ReactNode;
  [x: string]: any;
}

export default function AuthenticatedPage({
  breadcrumb,
  ...props
}: AuthenticatedPageProps) {
  const { currentUser }: AuthContextProps = useAuth();
  const location = useLocation();

  if (currentUser === null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/se_connecter" state={{ from: location }} replace />;
  }

  return (
    <Page sidebar={<Sidebar />} isManagedSidebar {...props}>
      <BackgroundImage />
      <Alerts />
      <Messages />
      <PageSection isFilled padding={{ default: "noPadding" }}>
        <Outlet />
      </PageSection>
    </Page>
  );
}
