import React, { useEffect, useState } from "react";
import NotAuthenticatedLoadingPage from "layout/NotAuthenticatedLoadingPage";
import { ICredentials, ICurrentUser, ISignUpForm } from "types";
import { getTokens, removeTokens, saveTokens } from "services/localStorage";
import {
  login,
  refreshTokens,
  getCurrentUser,
  updateCurrentUser,
  signUp,
} from "../services/api";

export interface AuthContextProps {
  currentUser: ICurrentUser | null;
  isAuthenticated: () => boolean;
  login: (credential: ICredentials) => Promise<void>;
  signUp: (credential: ISignUpForm) => Promise<void>;
  updateCurrentUser: (currentUser: ICurrentUser) => Promise<void>;
  logout: () => void;
}

const AuthContext = React.createContext({} as AuthContextProps);

type AuthProviderProps = {
  children: React.ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [currentUser, setCurrentUser] = useState<ICurrentUser | null>(null);

  useEffect(() => {
    const tokens = getTokens();
    if (tokens) {
      refreshTokens(tokens)
        .then(saveTokens)
        .then(getCurrentUser)
        .then(setCurrentUser)
        .catch(() => {
          removeTokens();
          setCurrentUser(null);
        })
        .then(() => setIsLoadingAuth(false));
    } else {
      setCurrentUser(null);
      setIsLoadingAuth(false);
    }
  }, []);

  if (isLoadingAuth) {
    return <NotAuthenticatedLoadingPage />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAuthenticated: () => {
          return currentUser !== null;
        },
        login: (credentials: ICredentials) => {
          return login(credentials)
            .then(saveTokens)
            .then(getCurrentUser)
            .then(setCurrentUser);
        },
        updateCurrentUser: (currentUser: ICurrentUser) => {
          return updateCurrentUser(currentUser)
            .then(getCurrentUser)
            .then(setCurrentUser);
        },
        signUp: (credentials: ISignUpForm) => {
          removeTokens();
          return signUp(credentials)
            .then(() => login(credentials))
            .then(saveTokens)
            .then(getCurrentUser)
            .then(setCurrentUser);
        },
        logout: () => {
          removeTokens();
          setCurrentUser(null);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
