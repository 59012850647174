import React, { useState } from "react";
import { Button, DropdownItem } from "@patternfly/react-core";
import {
  CopyIcon,
  TimesIcon,
  EditAltIcon,
  CheckIcon,
  WarningTriangleIcon,
} from "@patternfly/react-icons";
import KebabDropdown from "components/KebabDropdown";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Input } from "components/form";
import { IVariante } from "types";

interface TabTitleProps {
  variante: IVariante;
  onChange: (variante: IVariante) => void;
  onDuplicate: (variante: IVariante) => void;
  onDelete: (variante: IVariante) => void;
}

const OperationTitleSchema = Yup.object().shape({
  nom: Yup.string().required("Le nom de la variante est nécéssaire"),
});

export default function TabTitle({
  variante,
  onDuplicate,
  onChange,
  onDelete,
}: TabTitleProps) {
  const [editModeOn, setEditModeOne] = useState(false);
  const NavTitleDropdownItems = [
    <DropdownItem
      key="editer_variante"
      component="button"
      onClick={() => setEditModeOne(true)}
    >
      <EditAltIcon className="mr-2" /> Éditer
    </DropdownItem>,
    <DropdownItem
      key="onDuplicate_variante"
      component="button"
      onClick={() => onDuplicate(variante)}
    >
      <CopyIcon className="mr-2" />
      Dupliquer
    </DropdownItem>,
    <DropdownItem
      key="onDelete_variante"
      component="button"
      onClick={() => onDelete(variante)}
    >
      <WarningTriangleIcon className="mr-2" />
      Supprimer
    </DropdownItem>,
  ];
  return editModeOn ? (
    <Formik
      initialValues={variante}
      validationSchema={OperationTitleSchema}
      onSubmit={(v) => {
        setEditModeOne(false);
        onChange(v);
      }}
    >
      {({ isValid, dirty }) => (
        <Form className="pf-c-inline-edit pf-m-inline-editable">
          <div className="pf-c-inline-edit__group">
            <div className="pf-c-inline-edit__input">
              <Input id="nom" name="nom" type="text" isRequired />
            </div>
            <div className="pf-c-inline-edit__group pf-m-action-group pf-m-icon-group">
              <Button
                variant="link"
                type="submit"
                isDisabled={!(isValid && dirty)}
              >
                <CheckIcon />
              </Button>
              <div className="pf-c-inline-edit__action">
                <Button
                  variant="plain"
                  type="button"
                  onClick={() => setEditModeOne(false)}
                >
                  <TimesIcon />
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <span>
      {variante.nom}
      <KebabDropdown
        items={NavTitleDropdownItems}
        menuAppendTo={() => document.body}
      />
    </span>
  );
}
