import { INouvelleVariante } from "types";
import { format } from "date-fns";

export const VarianteInitiale: INouvelleVariante = {
  nom: "",
  projet: "",
  prix_de_revente: 0,
  duree_calcul: 5,
  prix_achat_FAI: 0,
  frais_de_notaire: 0,
  frais_de_garanties: 0,
  apport_eventuel: 0,
  date_deblocage: format(new Date(), "dd/MM/yyyy"),
  duree_pret: 20,
  differe: 0,
  taux_assurance: 0.3,
  taux_interet: 2.0,
  frais_de_gestion: 6,
  GLI: 0,
  taxe_fonciere: 0,
  assurance_PNO: 0,
  charges_copropriete: 0,
  frais_comptable: 0,
  CFE: 0,
  travaux: [
    {
      designation: "",
      montant: 0,
    },
    {
      designation: "",
      montant: 0,
    },
  ],
  lots: [
    {
      designation: "",
      loyer_actuel: 0,
      loyer_projete: 0,
      situation_locative: "LIBRE",
      superficie: 0,
    },
    {
      designation: "",
      loyer_actuel: 0,
      loyer_projete: 0,
      situation_locative: "LIBRE",
      superficie: 0,
    },
    {
      designation: "",
      loyer_actuel: 0,
      loyer_projete: 0,
      situation_locative: "LIBRE",
      superficie: 0,
    },
  ],
};

export default VarianteInitiale;
