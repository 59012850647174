import React from "react";
import {
  Card,
  CardBody,
  Flex,
  FlexItem,
  PageSection,
  PageSectionVariants,
  Text,
  TextContent,
} from "@patternfly/react-core";
import LoadingState from "components/LoadingState";

interface MainPageProps {
  breadcrumb?: React.ReactNode;
  children?: React.ReactNode;
  title: string;
  description?: string;
  isLoading?: boolean;
  topActions?: React.ReactNode;
}

export default function MainPage({
  breadcrumb,
  children,
  title,
  description,
  topActions,
  isLoading = false,
  ...props
}: MainPageProps) {
  return (
    <div {...props}>
      {breadcrumb && (
        <section className="pf-c-page__main-breadcrumb">{breadcrumb}</section>
      )}
      <PageSection variant={PageSectionVariants.light}>
        <Flex justifyContent={{ default: "justifyContentSpaceBetween" }}>
          <FlexItem>
            <TextContent>
              <Text component="h1">{title}</Text>
              {description && <Text component="p">{description}</Text>}
            </TextContent>
          </FlexItem>
          <FlexItem>{topActions}</FlexItem>
        </Flex>
      </PageSection>

      <PageSection variant={PageSectionVariants.default}>
        {isLoading ? (
          <Card>
            <CardBody>
              <LoadingState />
            </CardBody>
          </Card>
        ) : (
          children
        )}
      </PageSection>
    </div>
  );
}
