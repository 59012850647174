import React, { useEffect, useRef, useState } from "react";
import { useField } from "formik";
import { FormGroup, TextInput, TextInputProps } from "@patternfly/react-core";
import { evaluate } from "mathjs";
import {
  chaineVersMasqueNombre,
  chaineVersMasqueEuro,
  chaineVersMasquePourcentage,
  chaineVersMasqueSurface,
  chaineVersMasqueAnnees,
  chaineVersMasqueMois,
} from "./transform";

type NumberProps = {
  label?: string;
  labelIcon?: React.ReactElement;
  id: string;
  name: string;
  variante?:
    | "number"
    | "monnaie"
    | "surface"
    | "pourcentage"
    | "annee"
    | "mois";
  onUpdate?: (value: string) => void;
} & TextInputProps;

function mathEvaluate(value: string) {
  if (value.startsWith("=")) {
    const calc = value.substring(1);
    return evaluate(calc).toString() as string;
  }
  return value;
}

export default function Number({
  label,
  labelIcon,
  id,
  name,
  variante = "number",
  onUpdate = (s) => undefined,
  ...props
}: NumberProps) {
  const textInputRef = useRef<HTMLInputElement>(null);
  const [field, meta, helpers] = useField(name);
  const { setValue, setError, setTouched } = helpers;
  const validated = meta.touched && meta.error ? "error" : "default";
  const [valeurMask, setValeurMask] = useState("");
  const [isFocus, setIsFocused] = useState(false);

  useEffect(() => {
    if (variante === "number") {
      setValeurMask(chaineVersMasqueNombre(field.value));
    }
    if (variante === "monnaie") {
      setValeurMask(chaineVersMasqueEuro(field.value));
    }
    if (variante === "surface") {
      setValeurMask(chaineVersMasqueSurface(field.value));
    }
    if (variante === "pourcentage") {
      setValeurMask(chaineVersMasquePourcentage(field.value));
    }
    if (variante === "annee") {
      setValeurMask(chaineVersMasqueAnnees(field.value));
    }
    if (variante === "mois") {
      setValeurMask(chaineVersMasqueMois(field.value));
    }
  }, [field.value, variante]);

  useEffect(() => {
    if (isFocus) {
      textInputRef.current?.select();
    }
  }, [isFocus, textInputRef]);

  return (
    <FormGroup
      label={label?.replace(/ /g, "\u00a0")}
      labelIcon={labelIcon}
      isRequired={props.isRequired}
      fieldId={id}
      helperTextInvalid={meta.error}
      validated={validated}
      className="overflow-hidden"
    >
      <TextInput
        id={id}
        ref={textInputRef}
        {...field}
        {...props}
        value={isFocus ? field.value.toString() : valeurMask}
        validated={validated}
        onChange={(v) => {
          setValue(v);
        }}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            let value = field.value;
            try {
              setValue(mathEvaluate(value));
              setIsFocused(false);
              onUpdate(value);
            } catch (error) {
              setError("invalide");
            }
          }
        }}
        onClick={() => {
          setIsFocused(true);
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setTouched(true);
          let value = field.value;
          try {
            setValue(mathEvaluate(value));
            setIsFocused(false);
            onUpdate(value);
          } catch (error) {
            setError("invalide");
          }
        }}
        className="font-mono"
      />
    </FormGroup>
  );
}
