import { ListVariant, LoginPage } from "@patternfly/react-core";
import PasswordResetForm from "./PasswordResetForm";
import Logo from "images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { LoginText } from "./info";
import { confirmResetPassword } from "services/api";
import { showError, showSuccess } from "alerts/alertsActions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { parseErreur } from "./erreur";

export default function PasswordResetPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  let { uid, token } = useParams();

  return (
    <LoginPage
      footerListVariants={ListVariant.inline}
      brandImgSrc={Logo}
      brandImgAlt="Bopil logo"
      textContent={LoginText}
      loginTitle="Changer votre mot de passe"
      loginSubtitle="Renseignez votre nouveau mot de passe."
    >
      <PasswordResetForm
        onSubmit={({ password }) => {
          if (uid === undefined || token === undefined) {
            return;
          }
          confirmResetPassword(uid, token, password)
            .then(() => {
              dispatch(
                showSuccess(
                  "Nous avons changé votre mot de passe avec succès. Vous pouvez vous connecter maintenant."
                )
              );
              navigate("/se_connecter");
            })
            .catch((e) => {
              const erreur = parseErreur(e?.response?.data);
              if (erreur) {
                dispatch(showError(erreur));
              } else {
                dispatch(
                  showError(
                    "Nous sommes désolés, une erreur est survenue. Veuillez contacter le service client si l'erreur persiste. Merci"
                  )
                );
              }
            });
        }}
      />
    </LoginPage>
  );
}
