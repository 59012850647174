import { round } from "lodash";
import { ICharges, IConclusion, IDonneesFiscales, IVariante } from "types";
import { calculer_tableau_amortissement } from "./amortissement";
import { calculer_tableau_annualise } from "./tableau_annualise";
import {
  calcul_impot_sur_plus_value_particulier,
  calcul_impot_sur_societe,
  calcul_impot_LMP,
} from "./impots";

function recuperer_derniere_valeur<T, K extends keyof T>(
  tableau: T[],
  clef: K
) {
  return tableau.length === 0 ? 0 : tableau[tableau.length - 1][clef];
}

export function calculer_conclusion(
  variante: IVariante,
  donnees_fiscales: IDonneesFiscales = []
): IConclusion {
  const amortissement = calculer_tableau_amortissement(variante);
  const prix_achat_FAI = variante.prix_achat_FAI;
  const frais_de_notaire = variante.frais_de_notaire;
  const frais_de_garanties = variante.frais_de_garanties;
  const apport = variante.apport_eventuel;
  const montant_travaux = variante.travaux.reduce((acc, travail) => {
    acc += travail.montant;
    return acc;
  }, 0);
  const total_projet =
    prix_achat_FAI + frais_de_notaire + frais_de_garanties + montant_travaux;
  const superficie_totale = variante.lots.reduce((acc, lot) => {
    acc += lot.superficie;
    return acc;
  }, 0);
  const prix_m2_achat_TTC =
    superficie_totale === 0 ? 0 : total_projet / superficie_totale;
  const loyers = variante.lots.reduce((acc, lot) => {
    acc += lot.loyer_projete;
    return acc;
  }, 0);
  const frais_de_gestion_mensuel = (variante.frais_de_gestion / 100) * loyers;
  const GLI_mensuel = (variante.GLI / 100) * loyers;
  const taxe_fonciere = variante.taxe_fonciere;
  const assurance_PNO = variante.assurance_PNO;
  const charges_copropriete = variante.charges_copropriete;
  const frais_comptable = variante.frais_comptable;
  const CFE = variante.CFE;
  const mensualite_credit =
    amortissement.montant_mensualite_pendant_duree_calcul +
    amortissement.montant_assurance;
  const charges: ICharges = {
    mensualite_credit: {
      mensuel: mensualite_credit,
      annuel: mensualite_credit * 12,
    },
    frais_de_gestion: {
      mensuel: frais_de_gestion_mensuel,
      annuel: frais_de_gestion_mensuel * 12,
    },
    GLI: {
      mensuel: GLI_mensuel,
      annuel: GLI_mensuel * 12,
    },
    taxe_fonciere: {
      mensuel: taxe_fonciere / 12,
      annuel: taxe_fonciere,
    },
    assurance_PNO: {
      mensuel: assurance_PNO / 12,
      annuel: assurance_PNO,
    },
    charges_copropriete: {
      mensuel: charges_copropriete / 12,
      annuel: charges_copropriete,
    },
    frais_comptable: {
      mensuel: frais_comptable / 12,
      annuel: frais_comptable,
    },
    CFE: {
      mensuel: CFE / 12,
      annuel: CFE,
    },
  };
  const charges_mensuelles = Object.values(charges).reduce((acc, charge) => {
    acc += charge["mensuel"];
    return acc;
  }, 0);
  const bilan_mensuel = loyers - charges_mensuelles;
  const indice_au_100 =
    total_projet === 0 ? 0 : (bilan_mensuel * 100000) / total_projet;
  const rentabilite_brute =
    total_projet === 0 ? 0 : (loyers * 12 * 100) / total_projet;
  const rentabilite_nette =
    total_projet === 0
      ? 0
      : ((loyers + mensualite_credit - charges_mensuelles) * 12 * 100) /
        total_projet;
  const renta_cash_flow_sur_apport =
    apport === 0 ? 0 : (bilan_mensuel * 12 * 100) / apport;
  const pourcentage_cash_flow =
    loyers === 0 ? 0 : (bilan_mensuel * 100) / loyers;
  const rapport_credit_sur_loyer =
    loyers === 0 ? 0 : (mensualite_credit * 100) / loyers;

  const rounded_charges: ICharges = {
    ...charges,
  };

  for (const [k, v] of Object.entries(charges)) {
    rounded_charges[k as keyof ICharges] = {
      mensuel: round(v.mensuel, 2),
      annuel: round(v.annuel, 2),
    };
  }
  const tableau_annualise = calculer_tableau_annualise(
    variante,
    amortissement.tableau,
    donnees_fiscales
  );

  const prix_de_revente = variante.prix_de_revente;

  const amortissement_travaux_si_sup_a_5ans =
    variante.duree_calcul >= 5 ? (15 * prix_achat_FAI) / 100 : 0;
  const base_calcul_prix_session_particulier = Math.max(
    prix_achat_FAI + frais_de_notaire + montant_travaux,
    prix_achat_FAI + amortissement_travaux_si_sup_a_5ans
  );
  const base_calcul_prix_session_particulier_arrondi = round(
    base_calcul_prix_session_particulier,
    2
  );

  const plus_value_imposable_particulier =
    prix_de_revente - base_calcul_prix_session_particulier;
  const duree_calcul = variante.duree_calcul;
  const impot_plus_value_particulier = calcul_impot_sur_plus_value_particulier(
    plus_value_imposable_particulier,
    duree_calcul
  );

  const capital_restant = recuperer_derniere_valeur(
    tableau_annualise.FONCIER,
    "capital_restant"
  );

  const cashflow_FONCIER = recuperer_derniere_valeur(
    tableau_annualise.FONCIER,
    "cumul_cashflow"
  );
  const net_operation_FONCIER = round(
    cashflow_FONCIER +
      prix_de_revente -
      impot_plus_value_particulier -
      capital_restant -
      apport,
    2
  );

  const cashflow_BIC_LMNP = recuperer_derniere_valeur(
    tableau_annualise.BIC_LMNP,
    "cumul_cashflow"
  );
  const net_operation_BIC_LMNP = round(
    cashflow_BIC_LMNP +
      prix_de_revente -
      impot_plus_value_particulier -
      capital_restant -
      apport,
    2
  );

  const base_calcul_prix_session_BIC_LMP_amorti =
    tableau_annualise.BIC_LMP.length === 0
      ? 0
      : tableau_annualise.BIC_LMP[tableau_annualise.BIC_LMP.length - 1]
          .base_calcul_prix_session;

  const plus_value_imposable_BIC_LMP =
    prix_de_revente - base_calcul_prix_session_BIC_LMP_amorti;
  const impot_sur_societe_BIC_LMP =
    duree_calcul > 5 && loyers < 90000
      ? 0
      : calcul_impot_LMP(plus_value_imposable_BIC_LMP);
  const cashflow_BIC_LMP = recuperer_derniere_valeur(
    tableau_annualise.BIC_LMP,
    "cumul_cashflow"
  );
  const net_operation_BIC_LMP = round(
    cashflow_BIC_LMP +
      prix_de_revente -
      impot_sur_societe_BIC_LMP -
      capital_restant -
      apport,
    2
  );

  const base_imposable_IS = recuperer_derniere_valeur(
    tableau_annualise.IS,
    "base_imposable"
  );
  const base_calcul_prix_session_IS_amorti =
    tableau_annualise.IS.length === 0
      ? 0
      : tableau_annualise.IS[tableau_annualise.IS.length - 1]
          .base_calcul_prix_session;
  const plus_value_imposable_IS =
    base_imposable_IS + prix_de_revente - base_calcul_prix_session_IS_amorti;
  const impot_sur_societe_IS = calcul_impot_sur_societe(
    plus_value_imposable_IS
  );
  const cashflow_IS = recuperer_derniere_valeur(
    tableau_annualise.IS,
    "tresorerie_societe"
  );
  const net_operation_IS = round(
    cashflow_IS +
      prix_de_revente -
      impot_sur_societe_IS -
      capital_restant -
      apport,
    2
  );
  return {
    acquisition: {
      prix_achat_FAI,
      frais_de_notaire: frais_de_notaire,
      frais_de_garanties: frais_de_garanties,
      travaux: montant_travaux,
      apport: apport,
      total_projet: total_projet,
      total_a_financer: total_projet - apport,
      prix_m2_achat_TTC: round(prix_m2_achat_TTC, 2),
    },
    revente: {
      FONCIER: {
        cumul_cashflow: cashflow_FONCIER,
        duree_calcul,
        prix_de_revente,
        apport,
        capital_restant,
        base_calcul_prix_session: base_calcul_prix_session_particulier_arrondi,
        plus_value_imposable: plus_value_imposable_particulier,
        impots: impot_plus_value_particulier,
        net_operation: net_operation_FONCIER,
      },
      BIC_LMNP: {
        cumul_cashflow: cashflow_BIC_LMNP,
        duree_calcul,
        prix_de_revente,
        apport,
        capital_restant,
        base_calcul_prix_session: base_calcul_prix_session_particulier_arrondi,
        plus_value_imposable: plus_value_imposable_particulier,
        impots: impot_plus_value_particulier,
        net_operation: net_operation_BIC_LMNP,
      },
      BIC_LMP: {
        cumul_cashflow: cashflow_BIC_LMP,
        duree_calcul,
        prix_de_revente,
        apport,
        capital_restant,
        base_calcul_prix_session: base_calcul_prix_session_BIC_LMP_amorti,
        plus_value_imposable: plus_value_imposable_BIC_LMP,
        impots: impot_sur_societe_BIC_LMP,
        net_operation: net_operation_BIC_LMP,
      },
      IS: {
        cumul_cashflow: cashflow_IS,
        duree_calcul,
        prix_de_revente,
        apport,
        capital_restant,
        base_calcul_prix_session: base_calcul_prix_session_IS_amorti,
        plus_value_imposable: plus_value_imposable_IS,
        impots: impot_sur_societe_IS,
        net_operation: net_operation_IS,
      },
    },
    revenus: {
      loyers: { mensuel: round(loyers, 2), annuel: round(loyers * 12, 2) },
    },
    charges: rounded_charges,
    total_charges: {
      mensuel: round(charges_mensuelles, 2),
      annuel: round(charges_mensuelles * 12, 2),
    },
    bilan: {
      mensuel: round(bilan_mensuel, 2),
      annuel: round(bilan_mensuel * 12, 2),
    },
    statistiques: {
      indice_au_100: round(indice_au_100),
      rentabilite_brute: round(rentabilite_brute, 2),
      rentabilite_nette: round(rentabilite_nette, 2),
      renta_cash_flow_sur_apport: round(renta_cash_flow_sur_apport, 2),
      pourcentage_cash_flow: round(pourcentage_cash_flow, 2),
      rapport_credit_sur_loyer: round(rapport_credit_sur_loyer, 2),
    },
    amortissement: amortissement,
    tableau_annualise,
  };
}
