import React from "react";
import { TrashAltIcon } from "@patternfly/react-icons";
import { Button } from "@patternfly/react-core";

interface TrashButtonIconProps {
  className?: string;
  children?: React.ReactNode;
  [x: string]: any;
}

export default function TrashButtonIcon({
  className,
  children,
  ...props
}: TrashButtonIconProps) {
  return (
    <Button {...props} variant="secondary" isDanger isSmall>
      {children ? children : <TrashAltIcon />}
    </Button>
  );
}
