import { round } from "lodash";
import { IVariante } from "types";

function arrondirVariante(variante: IVariante): IVariante {
  variante.frais_de_notaire = round(variante.frais_de_notaire, 2);
  variante.frais_de_garanties = round(variante.frais_de_garanties, 2);
  return variante;
}

export function recalculer(
  ancienneVariante: IVariante,
  nouvelleVariante: IVariante
): IVariante {
  const variante = {
    ...nouvelleVariante,
  };
  if (ancienneVariante.prix_achat_FAI !== variante.prix_achat_FAI) {
    //todo mettre pourcentage_frais_notaire dans une variante
    const pourcentage_frais_notaire = 8;
    variante.frais_de_notaire =
      (variante.prix_achat_FAI * pourcentage_frais_notaire) / 100;
    const montant_travaux = variante.travaux.reduce((acc, travail) => {
      acc += travail.montant;
      return acc;
    }, 0);
    const total_projet =
      variante.prix_achat_FAI + variante.frais_de_notaire + montant_travaux;
    const total_a_financer = total_projet - variante.apport_eventuel;
    //todo mettre pourcentage_frais_de_garanties dans une variante
    const pourcentage_frais_de_garanties = 1.2;
    variante.frais_de_garanties =
      (total_a_financer * pourcentage_frais_de_garanties) / 100;
  }
  return arrondirVariante(variante);
}
