import { Number } from "components/form";
import { Formik, Form } from "formik";
import { IDonneeFiscale, IDonneeFiscaleForm } from "types";
import * as Yup from "yup";
import { chaineEnNombre } from "components/form/transform";

const DonneeFiscaleSchema = Yup.object().shape({
  revenu_net_imposable: Yup.string(),
  quotient_familial: Yup.string(),
});

function depuisFormulaireVersDonneeFiscale(
  form: IDonneeFiscaleForm
): IDonneeFiscale | undefined {
  try {
    return {
      annee: chaineEnNombre(form.annee),
      revenu_net_imposable: chaineEnNombre(form.revenu_net_imposable),
      quotient_familial: chaineEnNombre(form.quotient_familial),
    };
  } catch (e) {}
}

interface DonneeFiscaleFormProps {
  onSubmit: (variante: IDonneeFiscale) => void;
  [key: string]: any;
}

export default function DonneeFiscaleForm({
  onSubmit,
}: DonneeFiscaleFormProps) {
  return (
    <Formik
      initialValues={{
        annee: "2021",
        revenu_net_imposable: "0",
        quotient_familial: "1",
      }}
      validationSchema={DonneeFiscaleSchema}
      onSubmit={(values, actions) => {
        const s = depuisFormulaireVersDonneeFiscale(values);
        if (s) {
          onSubmit(s);
          actions.setSubmitting(false);
        }
      }}
    >
      <Form id="FormulaireDonneeFiscale" className="pf-c-form">
        <Number
          id="annee"
          data-testid="annee"
          label="Année"
          name="annee"
          variante="number"
        />
        <Number
          id="revenu_net_imposable"
          data-testid="revenu_net_imposable"
          label="Revenu net imposable"
          name="revenu_net_imposable"
          variante="monnaie"
        />
        <Number
          id="quotient_familial"
          data-testid="quotient_familial"
          label="Quotient familial"
          name="quotient_familial"
        />
      </Form>
    </Formik>
  );
}
