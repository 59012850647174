import React, { ReactNode } from "react";
import {
  Button,
  Modal,
  ModalVariant,
  Title,
  TitleSizes,
} from "@patternfly/react-core";
import useModal from "../hooks/useModal";
import TexteRouge from "./Texte/TexteRouge";

type ConfirmDeleteModalProps = {
  onOk: () => void;
  children: (open: () => void) => ReactNode;
  titre?: string;
  message?: string;
  okButton?: string;
  bouttonAnnuler?: string;
};

const ConfirmDeleteModal = ({
  titre = "Etes vous sur ?",
  message = "",
  okButton = "Supprimer",
  bouttonAnnuler = "Annuler",
  onOk,
  children,
}: ConfirmDeleteModalProps) => {
  const { isOpen, show, hide } = useModal(false);
  return (
    <>
      <Modal
        title="modale-confirm-supprimer"
        isOpen={isOpen}
        header={
          <Title headingLevel="h1" size={TitleSizes["2xl"]}>
            <TexteRouge>{titre}</TexteRouge>
          </Title>
        }
        onClose={hide}
        variant={ModalVariant.small}
        actions={[
          <Button
            key="confirmer"
            variant="danger"
            onClick={() => {
              hide();
              onOk();
            }}
          >
            {okButton}
          </Button>,
          <Button key="annuler" variant="link" onClick={hide} autoFocus>
            {bouttonAnnuler}
          </Button>,
        ]}
      >
        <TexteRouge>{message}</TexteRouge>
      </Modal>
      {children(show)}
    </>
  );
};

export default ConfirmDeleteModal;
