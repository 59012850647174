import alertsReducer from "alerts/alertsReducer";
import { createStore, applyMiddleware, combineReducers, Action } from "redux";
import thunk, {
  ThunkAction,
  ThunkDispatch,
  ThunkMiddleware,
} from "redux-thunk";

export const rootReducer = combineReducers({
  alerts: alertsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<R = void> = ThunkAction<R, RootState, null, Action>;
export type AppDispatch = ThunkDispatch<RootState, null, Action>;

const middleware: ThunkMiddleware<RootState, Action> = thunk;

const store = createStore(rootReducer, applyMiddleware(middleware));

export default store;
