import { useCallback, useEffect, useState } from "react";
import {
  BreadcrumbItem,
  Button,
  Modal,
  ModalVariant,
  PageSection,
  PageSectionVariants,
  Tab,
  Tabs,
  TabTitleText,
  Text,
  TextContent,
} from "@patternfly/react-core";
import { Link } from "react-router-dom";
import {
  creerDonneeFiscale,
  getVarianteParDefaut,
  getDonneesFiscales,
  supprimerDonneeFiscale,
  changeVarianteParDefaut,
  supprimerVarianteParDefaut,
} from "services/api";
import { IDonneesFiscales, IVariante } from "types";
import LoadingState from "components/LoadingState";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import FormulaireDonneeFiscale from "./FormulaireDonneeFiscale";
import TrashButtonIcon from "components/TrashButtonIcon";
import { chaineVersMasqueEuro } from "components/form/transform";
import MainPage from "layout/MainPage";
import VarianteInitiale from "projets/VarianteInitiale";
import VarianteForm from "projets/VarianteForm";
import { showError, showSuccess } from "alerts/alertsActions";
import BreadcrumbWithNav from "components/BreadcrumbWithNav";

export function DonneesFiscales() {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [donneesFiscales, setDonneesFiscales] = useState<IDonneesFiscales>([]);

  const getDonneesFiscalesCallback = useCallback(() => {
    getDonneesFiscales()
      .then((response) => {
        setDonneesFiscales(response.data.results);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getDonneesFiscalesCallback();
  }, [getDonneesFiscalesCallback]);

  if (isLoading) return <LoadingState />;

  return (
    <>
      <PageSection variant={PageSectionVariants.light}>
        {donneesFiscales.length === 0 ? (
          <TextContent>
            <Text component="p">
              Ajouter une information fiscale pour modifier le calcul de la
              rentabilité de vos projets dans le futur.
              <br />
              Par exemple vous prévoyez que votre enfant quitte votre foyer pour
              réaliser des études en 2025.
              <br />
              Vous pouvez prévoir une demi-part fiscale de moins en 2025.
            </Text>
          </TextContent>
        ) : (
          <table className="pf-c-table pf-m-grid-md pf-m-compact">
            <thead>
              <tr>
                <th>Année</th>
                <th>Revenu net imposable</th>
                <th>Quotient familial</th>
              </tr>
            </thead>
            <tbody>
              {donneesFiscales.map((donneeFiscale) => (
                <tr>
                  <td className="font-mono">{donneeFiscale.annee}</td>
                  <td className="font-mono">
                    {chaineVersMasqueEuro(donneeFiscale.revenu_net_imposable)}
                  </td>
                  <td className="font-mono">
                    {donneeFiscale.quotient_familial}
                  </td>
                  <td className="font-mono text-center">
                    <TrashButtonIcon
                      tabIndex={-1}
                      type="button"
                      onClick={() => {
                        supprimerDonneeFiscale(donneeFiscale).finally(
                          getDonneesFiscalesCallback
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </PageSection>
      <PageSection variant={PageSectionVariants.light}>
        <Modal
          title="Ajouter une information fiscale"
          isOpen={isModalOpen}
          variant={ModalVariant.small}
          onClose={() => setIsModalOpen(false)}
          actions={[
            <div className="mt-2">
              <Button
                key="submit_button"
                form="FormulaireDonneeFiscale"
                type="submit"
              >
                Ajouter
              </Button>
            </div>,
          ]}
        >
          <FormulaireDonneeFiscale
            onSubmit={(donneeFiscale) => {
              creerDonneeFiscale(donneeFiscale).finally(() => {
                getDonneesFiscalesCallback();
                setIsModalOpen(false);
              });
            }}
          />
        </Modal>
        <Button
          key="submit_button"
          form="FormulaireDonneeFiscale"
          type="button"
          onClick={() => setIsModalOpen(true)}
        >
          Ajouter une information fiscale
        </Button>
      </PageSection>
    </>
  );
}

export function VarianteParDefaut() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const [varianteParDefault, setVarianteParDefaut] = useState<IVariante | null>(
    null
  );
  useEffect(() => {
    getVarianteParDefaut()
      .then((v) => {
        setVarianteParDefaut({
          ...VarianteInitiale,
          ...v,
        });
      })
      .catch(console.error)
      .then(() => setIsLoading(false));
  }, []);

  if (isLoading) return <LoadingState />;

  return (
    <>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h4">Variante par défaut</Text>
          <Text component="p">
            Changer les valeurs utilisés par défaut lors de la création d'une
            variante.
          </Text>
          {varianteParDefault === null ? null : (
            <Button
              onClick={() =>
                supprimerVarianteParDefaut(varianteParDefault)
                  .then(() => {
                    dispatch(
                      showSuccess(
                        "La variante par défaut a bien bien été supprimée"
                      )
                    );
                    setVarianteParDefaut(VarianteInitiale as IVariante);
                    setVarianteParDefaut(null);
                  })
                  .catch(() =>
                    dispatch(
                      showError(
                        "Impossible de supprimer votre variante par défaut. Veuillez réessayer dans quelques minutes."
                      )
                    )
                  )
              }
            >
              Supprimer ma variante par défaut
            </Button>
          )}
        </TextContent>
      </PageSection>
      <PageSection
        variant={PageSectionVariants.light}
        style={{ paddingTop: 0 }}
      >
        <VarianteForm
          variante={
            varianteParDefault === null ? VarianteInitiale : varianteParDefault
          }
          estVarianteParDefaut
          onSubmit={(v) => {
            changeVarianteParDefaut(v)
              .then((r) => {
                dispatch(
                  showSuccess("La variante par défaut a bien été configurée")
                );
                setVarianteParDefaut({
                  ...VarianteInitiale,
                  ...r.data,
                });
              })
              .catch(() =>
                dispatch(
                  showError(
                    "Impossible de changer la variante par défaut. Veuillez réessayer dans quelques minutes."
                  )
                )
              );
          }}
          buttonText="Enregistrer"
        />
      </PageSection>
    </>
  );
}

export default function PageMonCompte() {
  const [activeTabKey, setActiveTabKey] = useState(0);

  return (
    <MainPage
      title="Mon compte"
      description="Changer vos paramètres"
      breadcrumb={
        <BreadcrumbWithNav>
          <BreadcrumbItem>
            <Link to="/mon_compte">Mon compte</Link>
          </BreadcrumbItem>
        </BreadcrumbWithNav>
      }
    >
      <Tabs
        activeKey={activeTabKey}
        onSelect={(event, tabIndex) => {
          if (tabIndex !== undefined) {
            const index = parseInt(tabIndex as string, 10);
            setActiveTabKey(index);
          }
        }}
        isBox
      >
        <Tab
          eventKey={0}
          title={<TabTitleText>Profil investisseur</TabTitleText>}
        >
          <DonneesFiscales />
        </Tab>
        <Tab
          eventKey={1}
          title={<TabTitleText>Variante par défaut</TabTitleText>}
        >
          <VarianteParDefaut />
        </Tab>
      </Tabs>
    </MainPage>
  );
}
