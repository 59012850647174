import { chaineVersMasqueEuro } from "components/form/transform";
import { IConclusionRevente } from "types";

interface ITableauRecapReventeProps {
  revente: IConclusionRevente;
}

export default function TableauRecapRevente({
  revente,
}: ITableauRecapReventeProps) {
  return (
    <table className="pf-c-table pf-m-grid-md pf-m-compact">
      <thead>
        <tr>
          <th>Type</th>
          <th className="text-right">Prix de vente</th>
          <th className="text-right">Capital restant dû</th>
          <th className="text-right">Base prix de cession</th>
          <th className="text-right">Impôts sur plus values</th>
          <th className="text-right">Apport</th>
          <th className="text-right">Cumul cashflow</th>
          <th className="text-right">Résultat net</th>
        </tr>
      </thead>
      <tbody className="font-mono">
        <tr>
          <td>FONCIER</td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.FONCIER.prix_de_revente)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.FONCIER.capital_restant)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.FONCIER.base_calcul_prix_session)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.FONCIER.impots)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.FONCIER.apport)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.FONCIER.cumul_cashflow)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.FONCIER.net_operation)}
          </td>
        </tr>
        <tr>
          <td>BIC LMNP</td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMNP.prix_de_revente)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMNP.capital_restant)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMNP.base_calcul_prix_session)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMNP.impots)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMNP.apport)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMNP.cumul_cashflow)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMNP.net_operation)}
          </td>
        </tr>
        <tr>
          <td>BIC LMP</td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMP.prix_de_revente)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMP.capital_restant)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMP.base_calcul_prix_session)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMP.impots)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMP.apport)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMP.cumul_cashflow)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.BIC_LMP.net_operation)}
          </td>
        </tr>
        <tr>
          <td>IS</td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.IS.prix_de_revente)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.IS.capital_restant)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.IS.base_calcul_prix_session)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.IS.impots)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.IS.apport)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.IS.cumul_cashflow)}
          </td>
          <td className="text-right">
            {chaineVersMasqueEuro(revente.IS.net_operation)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
