import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ color: "white" }}>
        <h1>Page non trouvée</h1>
        <p>Erreur 404</p>
        <p>Impossible de trouver la page que vous demandez</p>
        <Link to="/" style={{ color: "white", textDecoration: "underline" }}>
          Revenir à l'acceuil
        </Link>
      </div>
    </div>
  );
}
