import {
  Button,
  ListVariant,
  LoginMainFooterBandItem,
  LoginPage,
} from "@patternfly/react-core";
import ForgetPasswordForm from "./ForgetPasswordForm";
import Logo from "images/logo.png";
import { useNavigate } from "react-router-dom";
import { LoginText } from "./info";
import { resetPassword } from "services/api";
import { showError, showSuccess } from "alerts/alertsActions";
import { AppDispatch } from "store";
import { useDispatch } from "react-redux";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <LoginPage
      footerListVariants={ListVariant.inline}
      brandImgSrc={Logo}
      brandImgAlt="Bopil logo"
      textContent={LoginText}
      loginTitle="Mot de passe oublié"
      loginSubtitle="Pas de panique! Remplissez le formulaire ci-dessous et nous vous enverrons par email les informations pour créer un nouveau mot de passe."
      forgotCredentials={
        <LoginMainFooterBandItem>
          <Button variant="link" onClick={() => navigate("/se_connecter")}>
            Revenir à la page précédente
          </Button>
        </LoginMainFooterBandItem>
      }
    >
      <ForgetPasswordForm
        onSubmit={({ email }) => {
          resetPassword(email)
            .then(() => {
              dispatch(
                showSuccess(
                  `Un lien pour changer votre mot de passe a été envoyé à ${email}.`
                )
              );
            })
            .catch(() => {
              dispatch(
                showError(
                  "Nous sommes désolés, il nous est impossible de changer votre mot de passe."
                )
              );
            });
        }}
      />
    </LoginPage>
  );
}
