import React from "react";
import { Button } from "@patternfly/react-core";
import { Input } from "components/form";
import * as Yup from "yup";
import { Form, Formik } from "formik";

const ForgetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Votre nouveau mot de passe est trop court")
    .required("Votre nouveau mot de passe est obligatoire"),
});

interface PasswordResetFormProps {
  onSubmit: ({ password }: { password: string }) => void;
}

export default function ForgetPasswordForm({
  onSubmit,
}: PasswordResetFormProps) {
  return (
    <Formik
      initialValues={{ password: "" }}
      validationSchema={ForgetPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty }) => (
        <Form id="change_password_form" className="pf-c-form">
          <Input
            id="password"
            data-testid="password"
            label="Nouveau mot de passe"
            name="password"
            type="password"
            isRequired
          />
          <Button
            variant="primary"
            type="submit"
            isDisabled={!(isValid && dirty)}
          >
            Changer mon mot de passe
          </Button>
        </Form>
      )}
    </Formik>
  );
}
