import { chaineVersMasqueEuro } from "components/form/transform";
import React, { useEffect, useState } from "react";
import {
  IConclusion,
  IRevenusDeLocation,
  IVariante,
  ITableauAnnualiseFoncier,
  ITableauAnnualiseBICLMNP,
  ITableauAnnualiseIS,
  ITableauAnnualiseBICLMP,
  IDonneesFiscales,
} from "types";
import hash from "object-hash";
import {
  Flex,
  FlexItem,
  FormSelect,
  FormSelectOption,
  PageSection,
  PageSectionVariants,
  Select,
  SelectOption,
  SelectVariant,
  Tab,
  Tabs,
  TabTitleText,
} from "@patternfly/react-core";
import QuestionMarkHelper from "components/QuestionMarkHelper";
import { calculer_conclusion } from "variante/conclusion";
import { Link } from "react-router-dom";
import useLocalStorage from "hooks/useLocalStorage";
import { getDonneesFiscales } from "services/api";
import Recapitulatif from "./Recapitulatif";
import TableauTresorerie from "./TableauTresorerie";
import TableauAmortissement from "./TableauAmortissement";
import TableauRecapRevente from "./TableauRecapRevente";

interface TableauAnnualiseFoncierProps {
  tableau: ITableauAnnualiseFoncier;
  SelecteurRevenuDeLocation: React.ReactNode;
}

export function TableauAnnualiseFoncier({
  tableau,
  SelecteurRevenuDeLocation,
}: TableauAnnualiseFoncierProps) {
  const [estOuvert, setEstOuvert] = useState(false);
  const [options, setOptions] = useLocalStorage(
    "colonnes_tableau_annualise_foncier7",
    [
      {
        value: "annee",
        label: "Année",
        isChecked: true,
        className: "text-left",
      },
      {
        value: "report_annee_precendente",
        label: "Report N-1",
        isChecked: false,
        className: "text-right",
        aide: "Cette somme correspond à un éventuel déficit foncier de l'année antérieure (année N-1) qui vient s'imputer sur le résultat de l'année actuelle (année N)",
      },
      {
        value: "loyers",
        label: "Loyers",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "charges_ponctuelles",
        label: "Charges déductibles",
        isChecked: true,
        className: "text-right",
        aide: "L'ensemble des charges déductibles de l'exercice qui sont ponctuels (travaux, frais d'acquisition, etc)",
      },
      {
        value: "charges_recurrentes",
        label: "Charges récurrentes",
        isChecked: true,
        className: "text-right",
        aide: "Ensemble des charges invariables qui pèse sur votre investissement (gestion, taxe foncière, assurances, etc)",
      },
      {
        value: "charges_specifiques",
        label: "Charges spécifiques",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "amortissement",
        label: "Amortissement",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "credit",
        label: "Crédit",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "cashflow_brut",
        label: "Cashflow brut",
        isChecked: true,
        className: "text-right",
        aide: "Excédent brut de trésorerie de votre opération sans impact de la fiscalité",
      },
      {
        value: "base_imposable",
        label: "Base imposable revenus fonciers",
        isChecked: false,
        className: "text-right",
        aide: "Montant sur lequel les impôts vont se baser pour calculer vos charges sociales et votre impôt",
      },
      {
        value: "revenu_net_imposable",
        label: "Base imposable salaire",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "revenu_fiscal_reference",
        label: "RFR",
        isChecked: true,
        className: "text-right",
        aide: "Revenu Fiscal de Référence",
      },
      {
        value: "impots",
        label: "Impôts revenu global",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "prelevement_sociaux",
        label: "PS sur revenus foncier",
        isChecked: false,
        className: "text-right",
        aide: "Prélèvement sociaux de 17,2% applicable sur votre base imposable",
      },
      {
        value: "impot_total_investissement",
        label: "Impôt total investissement",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "cashflow_net",
        label: "Cashflow net/an",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "rente_par_mois",
        label: "Cashflow net/mois",
        isChecked: true,
        className: "text-right",
      },
    ],
    [
      "colonnes_tableau_annualise_foncier5",
      "colonnes_tableau_annualise_foncier6",
    ]
  );
  const optionsSelectionnees = options.filter((o) => o.isChecked);
  return (
    <>
      <div style={{ marginBottom: "1em", marginTop: "2em" }}>
        <h3 style={{ marginBottom: ".5em" }}>Revenu de location</h3>
        <Flex>
          <FlexItem>{SelecteurRevenuDeLocation}</FlexItem>
          <FlexItem>
            <Select
              variant={SelectVariant.checkbox}
              onToggle={() => setEstOuvert(!estOuvert)}
              onSelect={(event, newSelection) => {
                const s = newSelection as string;
                setOptions(
                  options.map((o) => {
                    if (o.value === s) {
                      return { ...o, isChecked: !o.isChecked };
                    }
                    return { ...o };
                  })
                );
              }}
              selections={optionsSelectionnees.map((o) => o.value)}
              isOpen={estOuvert}
              placeholderText="Filtrer les colonnes"
              menuAppendTo={() => document.body}
            >
              {options.map((option, i) => (
                <SelectOption key={i} value={option.value}>
                  {option.label}
                </SelectOption>
              ))}
            </Select>
          </FlexItem>
        </Flex>
        <Link to="/mon_compte">modifier mon profil investisseur</Link>
      </div>
      <table className="pf-c-table pf-m-grid-md pf-m-compact">
        <thead>
          <tr>
            {optionsSelectionnees.map((o, i) => (
              <th key={i} className={o.className}>
                {o.label}
                {o.aide ? (
                  <span className="ml-2">
                    <QuestionMarkHelper content={o.aide} className="text-xs" />
                  </span>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableau.map((ligne, i) => {
            return (
              <tr
                key={ligne.annee}
                className={`font-mono ${
                  i % 2 === 0 ? "bg-white" : "bg-gray-200"
                }`}
              >
                {optionsSelectionnees.map((o, i) => (
                  <td key={i} className={o.className}>
                    {o.value === "annee"
                      ? ligne.annee
                      : chaineVersMasqueEuro((ligne as any)[o.value])}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

interface TableauAnnualiseBICLMNPProps {
  tableau: ITableauAnnualiseBICLMNP;
  SelecteurRevenuDeLocation: React.ReactNode;
}

export function TableauAnnualiseBICLMNP({
  tableau,
  SelecteurRevenuDeLocation,
}: TableauAnnualiseBICLMNPProps) {
  const [estOuvert, setEstOuvert] = useState(false);
  const [options, setOptions] = useLocalStorage(
    "colonnes_tableau_annualise_BIC_LMNP7",
    [
      {
        value: "annee",
        label: "Année",
        isChecked: true,
        className: "text-left",
      },
      {
        value: "report_annee_precendente",
        label: "Report N-1",
        isChecked: false,
        className: "text-right",
        aide: "Cette somme correspond à un éventuel déficit de l'année antérieure (année N-1) qui vient s'imputer sur le résultat de l'année actuelle (année N)",
      },
      {
        value: "loyers",
        label: "Loyers",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "charges_ponctuelles",
        label: "Charges déductibles",
        isChecked: true,
        className: "text-right",
        aide: "L'ensemble des charges déductibles de l'exercice qui sont ponctuels (travaux, frais d'acquisition, etc)",
      },
      {
        value: "charges_recurrentes",
        label: "Charges récurrentes",
        isChecked: true,
        className: "text-right",
        aide: "Ensemble des charges invariables qui pèse sur votre investissement (gestion, taxe foncière, assurances, etc)",
      },
      {
        value: "charges_specifiques",
        label: "Charges spécifiques",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "amortissement",
        label: "Amortissement",
        isChecked: false,
        className: "text-right",
        aide: "Prise en compte comptablement d'une vétusté du bien immobilier (2% par an du prix d'aquisition)",
      },
      {
        value: "credit",
        label: "Crédit",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "cashflow_brut",
        label: "Cashflow brut",
        isChecked: true,
        className: "text-right",
        aide: "Excédent brut de trésorerie de votre opération sans impact de la fiscalité",
      },
      {
        value: "base_imposable",
        label: "Base imposable BIC",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "revenu_net_imposable",
        label: "Base imposable salaire",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "revenu_fiscal_reference",
        label: "RFR",
        isChecked: true,
        className: "text-right",
        aide: "Revenu Fiscal de Référence",
      },
      {
        value: "impots",
        label: "Impôts revenu global",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "prelevement_sociaux",
        label: "PS sur revenus BIC",
        isChecked: false,
        className: "text-right",
        aide: "Prélèvement sociaux de 17,2% applicable sur votre base imposable",
      },
      {
        value: "impot_total_investissement",
        label: "Impôt total investissement",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "cashflow_net",
        label: "Cashflow net/an",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "rente_par_mois",
        label: "Cashflow net/mois",
        isChecked: true,
        className: "text-right",
      },
    ],
    [
      "colonnes_tableau_annualise_BIC_LMNP5",
      "colonnes_tableau_annualise_BIC_LMNP6",
    ]
  );
  const optionsSelectionnees = options.filter((o) => o.isChecked);
  return (
    <>
      <div style={{ marginBottom: "1em", marginTop: "2em" }}>
        <h3 style={{ marginBottom: ".5em" }}>Revenu de location</h3>
        <Flex>
          <FlexItem>{SelecteurRevenuDeLocation}</FlexItem>
          <FlexItem>
            <Select
              variant={SelectVariant.checkbox}
              onToggle={() => setEstOuvert(!estOuvert)}
              onSelect={(event, newSelection) => {
                const s = newSelection as string;
                setOptions(
                  options.map((o) => {
                    if (o.value === s) {
                      return { ...o, isChecked: !o.isChecked };
                    }
                    return { ...o };
                  })
                );
              }}
              selections={optionsSelectionnees.map((o) => o.value)}
              isOpen={estOuvert}
              placeholderText="Filtrer les colonnes"
              menuAppendTo={() => document.body}
            >
              {options.map((option, i) => (
                <SelectOption key={i} value={option.value}>
                  {option.label}
                </SelectOption>
              ))}
            </Select>
          </FlexItem>
        </Flex>
        <Link to="/mon_compte">modifier mon profil investisseur</Link>
      </div>
      <table className="pf-c-table pf-m-grid-md pf-m-compact">
        <thead>
          <tr>
            {optionsSelectionnees.map((o, i) => (
              <th key={i} className={o.className}>
                {o.label}
                {o.aide ? (
                  <span className="ml-2">
                    <QuestionMarkHelper content={o.aide} className="text-xs" />
                  </span>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableau.map((ligne, i) => {
            return (
              <tr
                key={ligne.annee}
                className={`font-mono ${
                  i % 2 === 0 ? "bg-white" : "bg-gray-200"
                }`}
              >
                {optionsSelectionnees.map((o, i) => (
                  <td key={i} className={o.className}>
                    {o.value === "annee"
                      ? ligne.annee
                      : chaineVersMasqueEuro((ligne as any)[o.value])}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

interface TableauAnnualiseBICLMPProps {
  tableau: ITableauAnnualiseBICLMP;
  SelecteurRevenuDeLocation: React.ReactNode;
}

export function TableauAnnualiseBICLMP({
  tableau,
  SelecteurRevenuDeLocation,
}: TableauAnnualiseBICLMPProps) {
  const [estOuvert, setEstOuvert] = useState(false);
  const [options, setOptions] = useLocalStorage(
    "colonnes_tableau_annualise_BIC_LMP7",
    [
      {
        value: "annee",
        label: "Année",
        isChecked: true,
        className: "text-left",
      },
      {
        value: "report_annee_precendente",
        label: "Report N-1",
        isChecked: false,
        className: "text-right",
        aide: "Cette somme correspond à un éventuel déficit de l'année antérieure (année N-1) qui vient s'imputer sur le résultat de l'année actuelle (année N)",
      },
      {
        value: "loyers",
        label: "Loyers",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "charges_ponctuelles",
        label: "Charges déductibles",
        isChecked: true,
        className: "text-right",
        aide: "L'ensemble des charges déductibles de l'exercice qui sont ponctuels (travaux, frais d'acquisition, etc)",
      },
      {
        value: "charges_recurrentes",
        label: "Charges récurrentes",
        isChecked: true,
        className: "text-right",
        aide: "Ensemble des charges invariables qui pèse sur votre investissement (gestion, taxe foncière, assurances, etc)",
      },
      {
        value: "charges_specifiques",
        label: "Charges spécifiques",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "amortissement",
        label: "Amortissement",
        isChecked: false,
        className: "text-right",
        aide: "Prise en compte comptablement d'une vétusté du bien immobilier (2% par an du prix d'aquisition)",
      },
      {
        value: "credit",
        label: "Crédit",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "cashflow_brut",
        label: "Cashflow brut",
        isChecked: true,
        className: "text-right",
        aide: "Excédent brut de trésorerie de votre opération sans impact de la fiscalité",
      },
      {
        value: "base_imposable",
        label: "Base imposable BIC",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "revenu_net_imposable",
        label: "Base imposable salaire",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "revenu_fiscal_reference",
        label: "RFR",
        isChecked: true,
        className: "text-right",
        aide: "Revenu Fiscal de Référence",
      },
      {
        value: "impots",
        label: "Impôts revenu global",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "cotisations_sociales",
        label: "CS sur revenus BIC",
        isChecked: false,
        className: "text-right",
        aide: "Cotisations sociales sur la base imposable. Un minimum est applicable",
      },
      {
        value: "impot_total_investissement",
        label: "Impôt total investissement",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "cashflow_net",
        label: "Cashflow net/an",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "rente_par_mois",
        label: "Cashflow net/mois",
        isChecked: true,
        className: "text-right",
      },
    ],
    [
      "colonnes_tableau_annualise_BIC_LMP5",
      "colonnes_tableau_annualise_BIC_LMP6",
    ]
  );
  const optionsSelectionnees = options.filter((o) => o.isChecked);
  return (
    <>
      <div style={{ marginBottom: "1em", marginTop: "2em" }}>
        <h3 style={{ marginBottom: ".5em" }}>Revenu de location</h3>
        <Flex>
          <FlexItem>{SelecteurRevenuDeLocation}</FlexItem>
          <FlexItem>
            <Select
              variant={SelectVariant.checkbox}
              onToggle={() => setEstOuvert(!estOuvert)}
              onSelect={(event, newSelection) => {
                const s = newSelection as string;
                setOptions(
                  options.map((o) => {
                    if (o.value === s) {
                      return { ...o, isChecked: !o.isChecked };
                    }
                    return { ...o };
                  })
                );
              }}
              selections={optionsSelectionnees.map((o) => o.value)}
              isOpen={estOuvert}
              placeholderText="Filtrer les colonnes"
              menuAppendTo={() => document.body}
            >
              {options.map((option, i) => (
                <SelectOption key={i} value={option.value}>
                  {option.label}
                </SelectOption>
              ))}
            </Select>
          </FlexItem>
        </Flex>
        <Link to="/mon_compte">modifier mon profil investisseur</Link>
      </div>
      <table className="pf-c-table pf-m-grid-md pf-m-compact">
        <thead>
          <tr>
            {optionsSelectionnees.map((o, i) => (
              <th key={i} className={o.className}>
                {o.label}
                {o.aide ? (
                  <span className="ml-2">
                    <QuestionMarkHelper content={o.aide} className="text-xs" />
                  </span>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableau.map((ligne, i) => {
            return (
              <tr
                key={ligne.annee}
                className={`font-mono ${
                  i % 2 === 0 ? "bg-white" : "bg-gray-200"
                }`}
              >
                {optionsSelectionnees.map((o, i) => (
                  <td key={i} className={o.className}>
                    {o.value === "annee"
                      ? ligne.annee
                      : chaineVersMasqueEuro((ligne as any)[o.value])}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

interface TableauAnnualiseISProps {
  tableau: ITableauAnnualiseIS;
  SelecteurRevenuDeLocation: React.ReactNode;
}

export function TableauAnnualiseIS({
  tableau,
  SelecteurRevenuDeLocation,
}: TableauAnnualiseISProps) {
  const [estOuvert, setEstOuvert] = useState(false);
  const [options, setOptions] = useLocalStorage(
    "colonnes_tableau_annualise_IS6",
    [
      {
        value: "annee",
        label: "Année",
        isChecked: true,
        className: "text-left",
      },
      {
        value: "report_annee_precendente",
        label: "Report N-1",
        isChecked: false,
        className: "text-right",
        aide: "Cette somme correspond à un éventuel déficit de l'année antérieure (année N-1) qui vient s'imputer sur le résultat de l'année actuelle (année N)",
      },
      {
        value: "loyers",
        label: "Loyers",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "charges_ponctuelles",
        label: "Charges déductibles",
        isChecked: true,
        className: "text-right",
        aide: "L'ensemble des charges déductibles de l'exercice qui sont ponctuels (travaux, frais d'acquisition, etc)",
      },
      {
        value: "charges_recurrentes",
        label: "Charges récurrentes",
        isChecked: true,
        className: "text-right",
        aide: "Ensemble des charges invariables qui pèse sur votre investissement (gestion, taxe foncière, assurances, etc)",
      },
      {
        value: "charges_specifiques",
        label: "Charges spécifiques",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "amortissement",
        label: "Amortissement",
        isChecked: false,
        className: "text-right",
        aide: "Prise en compte comptablement d'une vétusté du bien immobilier (2% par an du prix d'aquisition)",
      },
      {
        value: "credit",
        label: "Crédit",
        isChecked: true,
        className: "text-right",
      },
      {
        value: "cashflow_brut",
        label: "Cashflow brut",
        isChecked: true,
        className: "text-right",
        aide: "Excédent brut de trésorerie de votre opération sans impact de la fiscalité",
      },
      {
        value: "base_imposable",
        label: "Base imposable avant IS",
        isChecked: false,
        className: "text-right",
      },
      {
        value: "IS",
        label: "IS",
        isChecked: true,
        className: "text-right",
        aide: "Montant de l'impôt sur les sociétés dû",
      },
      {
        value: "tresorerie_societe",
        label: "Trésorerie société",
        isChecked: false,
        className: "text-right",
        aide: "Montant d'argent disponible dans la société hors capitaux numéraires avant distribution de dividende",
      },
      {
        value: "resultat_distribuable",
        label: "Résultat distribuable",
        isChecked: false,
        className: "text-right",
        aide: "Il correspond à la base imposable minoré de l'impôt. C'est le résultat net d'exercice. Cette somme peut-être récupéré sous forme d'un dividende ou être mise en réserve",
      },
      {
        value: "disponibilite",
        label: "Disponibilité",
        isChecked: false,
        className: "text-right",
        aide: "Montant d'argent disponible dans la société hors capitaux numéraires après distribution de dividende",
      },
      {
        value: "net_percu",
        label: "Net perçu associé",
        isChecked: true,
        className: "text-right",
        aide: "Un dividende est soumis au Prélèvement forfaitaire unique de 30%, le net perçu associé correspond au dividende net perçu",
      },
      {
        value: "rente_par_mois",
        label: "Cashflow net/mois",
        isChecked: true,
        className: "text-right",
      },
    ],
    ["colonnes_tableau_annualise_IS5"]
  );
  const optionsSelectionnees = options.filter((o) => o.isChecked);
  return (
    <>
      <div style={{ marginBottom: "1em", marginTop: "2em" }}>
        <h3 style={{ marginBottom: ".5em" }}>Revenu de location</h3>
        <Flex>
          <FlexItem>{SelecteurRevenuDeLocation}</FlexItem>
          <FlexItem>
            <Select
              variant={SelectVariant.checkbox}
              onToggle={() => setEstOuvert(!estOuvert)}
              onSelect={(event, newSelection) => {
                const s = newSelection as string;
                setOptions(
                  options.map((o) => {
                    if (o.value === s) {
                      return { ...o, isChecked: !o.isChecked };
                    }
                    return { ...o };
                  })
                );
              }}
              selections={optionsSelectionnees.map((o) => o.value)}
              isOpen={estOuvert}
              placeholderText="Filtrer les colonnes"
              menuAppendTo={() => document.body}
            >
              {options.map((option, i) => (
                <SelectOption key={i} value={option.value}>
                  {option.label}
                </SelectOption>
              ))}
            </Select>
          </FlexItem>
        </Flex>
        <Link to="/mon_compte">modifier mon profil investisseur</Link>
      </div>
      <table className="pf-c-table pf-m-compact">
        <thead>
          <tr>
            {optionsSelectionnees.map((o, i) => (
              <th key={i} className={o.className}>
                {o.label}
                {o.aide ? (
                  <span className="ml-2">
                    <QuestionMarkHelper content={o.aide} className="text-xs" />
                  </span>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableau.map((ligne, i) => {
            return (
              <tr
                key={ligne.annee}
                className={`font-mono ${
                  i % 2 === 0 ? "bg-white" : "bg-gray-200"
                }`}
              >
                {optionsSelectionnees.map((o, i) => (
                  <td key={i} className={o.className}>
                    {o.value === "annee"
                      ? ligne.annee
                      : chaineVersMasqueEuro((ligne as any)[o.value])}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

interface ConclusionProps {
  variante: IVariante;
}

export default function Conclusion({ variante }: ConclusionProps) {
  const [activeTabKey, setActiveTabKey] = useLocalStorage<number>(
    "onglet_tableau",
    0
  );
  const [donneesFiscales, setDonneesFiscales] = useState<IDonneesFiscales>([]);
  const [conclusion, setConclusion] = useState<IConclusion | null>(null);
  const [revenu_de_location, setRevenuDeLocation] =
    useLocalStorage<IRevenusDeLocation>("revenu_de_location2", "FONCIER", [
      "revenu_de_location",
    ]);
  const varianteHash = hash(variante);
  useEffect(() => {
    setConclusion(calculer_conclusion(variante, donneesFiscales));
  }, [variante, donneesFiscales, varianteHash]);

  useEffect(() => {
    getDonneesFiscales().then((response) => {
      setDonneesFiscales(response.data.results);
    });
  }, []);

  if (conclusion === null) return null;

  const SelecteurRevenuDeLocation = (
    <FormSelect
      id="select_revenu_location"
      value={revenu_de_location}
      onChange={(value) => {
        setRevenuDeLocation(value as IRevenusDeLocation);
      }}
    >
      <FormSelectOption value="FONCIER" label="Foncier" />
      <FormSelectOption value="BIC_LMNP" label="BIC LMNP" />
      <FormSelectOption value="BIC_LMP" label="BIC LMP" />
      <FormSelectOption value="IS" label="IS" />
    </FormSelect>
  );
  return (
    <div>
      <PageSection
        variant={PageSectionVariants.light}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Recapitulatif conclusion={conclusion} />
      </PageSection>
      <PageSection variant={PageSectionVariants.light}>
        <Tabs
          activeKey={activeTabKey}
          onSelect={(event, tabIndex) => {
            if (tabIndex !== undefined) {
              const index = parseInt(tabIndex as string, 10);
              setActiveTabKey(index);
            }
          }}
          isBox
        >
          <Tab eventKey={0} title={<TabTitleText>Trésorerie</TabTitleText>}>
            <TableauTresorerie conclusion={conclusion} />
          </Tab>
          <Tab
            eventKey={1}
            title={<TabTitleText>Tableau amortissement</TabTitleText>}
          >
            <TableauAmortissement tableau={conclusion.amortissement.tableau} />
          </Tab>
          <Tab
            eventKey={2}
            title={<TabTitleText>Tableau annualisé</TabTitleText>}
          >
            {variante.duree_calcul === 0 ? (
              <div>
                <span className="text-base leading-6 font-medium text-gray-900">
                  Pour voir le tableau annualisé, vous devez lancer le calcul
                  sur une durée supérieure à 1 an
                </span>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <div className="mb-10">
                  <TableauRecapRevente revente={conclusion.revente} />
                </div>
                <div>
                  {revenu_de_location === "FONCIER" ? (
                    <TableauAnnualiseFoncier
                      tableau={conclusion.tableau_annualise.FONCIER}
                      SelecteurRevenuDeLocation={SelecteurRevenuDeLocation}
                    />
                  ) : revenu_de_location === "IS" ? (
                    <TableauAnnualiseIS
                      tableau={conclusion.tableau_annualise.IS}
                      SelecteurRevenuDeLocation={SelecteurRevenuDeLocation}
                    />
                  ) : revenu_de_location === "BIC_LMNP" ? (
                    <TableauAnnualiseBICLMNP
                      tableau={conclusion.tableau_annualise.BIC_LMNP}
                      SelecteurRevenuDeLocation={SelecteurRevenuDeLocation}
                    />
                  ) : (
                    <TableauAnnualiseBICLMP
                      tableau={conclusion.tableau_annualise.BIC_LMP}
                      SelecteurRevenuDeLocation={SelecteurRevenuDeLocation}
                    />
                  )}
                </div>
              </div>
            )}
          </Tab>
        </Tabs>
      </PageSection>
    </div>
  );
}
