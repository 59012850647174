import React, { useCallback, useEffect, useState } from "react";
import { Input } from "components/form";
import * as Yup from "yup";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Modal, ModalVariant } from "@patternfly/react-core";
import { IPartage, IProjet } from "types";
import useModal from "hooks/useModal";
import {
  getPartagesForProjet,
  partagerUnProjet,
  supprimerPartage,
} from "services/api";
import TrashButtonIcon from "components/TrashButtonIcon";
import { WarningTriangleIcon } from "@patternfly/react-icons";

const SchemaCreerVariante = Yup.object().shape({
  email: Yup.string()
    .email("Cet email est invalide")
    .required("L'email est requis"),
});

function BoutonPartager({ ...props }: { [x: string]: any }) {
  const { submitForm } = useFormikContext();
  return (
    <Button {...props} onClick={submitForm}>
      Partager
    </Button>
  );
}

interface PartagerProjetModalProps {
  projet: IProjet;
  children: (show: () => void) => React.ReactNode;
}

export default function PartagerProjetModal({
  projet,
  children,
}: PartagerProjetModalProps) {
  const { isOpen, show, hide } = useModal(false);
  const [error, setError] = useState<string | null>(null);
  const [partages, setPartages] = useState<IPartage[]>([]);

  const getPartagesCallback = useCallback(() => {
    setError(null);
    getPartagesForProjet(projet).then((response) => {
      setPartages(response.data.results);
    });
  }, [projet]);

  useEffect(() => {
    getPartagesCallback();
  }, [getPartagesCallback]);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={SchemaCreerVariante}
        onSubmit={({ email }) => {
          partagerUnProjet(projet, email)
            .then(getPartagesCallback)
            .catch(() => {
              setError(
                `Impossible de partager ce projet avec ${email}. Assurez vous que l'email est correct.`
              );
            });
        }}
      >
        <Modal
          variant={ModalVariant.medium}
          title="Partager avec un utilisateur de Bopil"
          isOpen={isOpen}
          onClose={hide}
          actions={[
            <BoutonPartager key="confirm" variant="primary" />,
            <Button key="cancel" variant="link" onClick={hide}>
              Fermer
            </Button>,
          ]}
        >
          <Form id="share_projet" className="pf-c-form">
            <Input
              id="email"
              data-testid="email"
              label="Email"
              name="email"
              isRequired
            />
          </Form>
          <div className="mt-5">
            {error === null ? (
              <p className="mb-3 text-yellow-500">
                <WarningTriangleIcon /> Un partage donne un contrôle total au
                projet
              </p>
            ) : (
              <p className="font-bold mb-3 text-red-500">{error}</p>
            )}
          </div>
          {partages.length === 0 ? (
            <div className="mt-5">
              <p className="font-bold mb-3">
                Ce projet n'a pas de partage actif
              </p>
            </div>
          ) : (
            <div className="my-5">
              <p className="font-bold mb-3">Partagé avec</p>
              {partages.map((p, i) => (
                <div key={i} className="flex items-center">
                  {p.email}
                  <TrashButtonIcon
                    type="button"
                    onClick={() => {
                      supprimerPartage(p).then(getPartagesCallback);
                    }}
                    className="ml-5"
                  />
                </div>
              ))}
            </div>
          )}
        </Modal>
      </Formik>
      {children(show)}
    </>
  );
}
