import {
  ListVariant,
  LoginMainFooterBandItem,
  LoginPage,
} from "@patternfly/react-core";
import SignUpForm from "./SignUpForm";
import Logo from "images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { LoginText } from "./info";
import { useAuth } from "./authContext";
import { parseErreur } from "./erreur";
import { showError } from "alerts/alertsActions";
import { AppDispatch } from "store";
import { useDispatch } from "react-redux";

export default function SignUpPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { signUp } = useAuth();
  return (
    <LoginPage
      footerListVariants={ListVariant.inline}
      brandImgSrc={Logo}
      brandImgAlt="Bopil logo"
      textContent={LoginText}
      loginTitle="Créer un compte"
      loginSubtitle="Renseignez vos informations pour créer un compte."
      signUpForAccountMessage={
        <LoginMainFooterBandItem>
          Déjà inscrit ? <Link to="/se_connecter">Connectez vous.</Link>
        </LoginMainFooterBandItem>
      }
    >
      <SignUpForm
        onSubmit={(credentials) => {
          return signUp(credentials)
            .then(() => navigate("/projets"))
            .catch((e) => {
              const erreur = parseErreur(e?.response?.data);
              if (erreur) {
                dispatch(showError(erreur));
              } else {
                dispatch(
                  showError(
                    "Nous sommes désolés, une erreur est survenue. Veuillez contacter le service client si l'erreur persiste. Merci."
                  )
                );
              }
            });
        }}
      />
    </LoginPage>
  );
}
