import { useField } from "formik";
import { FormGroup, CheckboxProps } from "@patternfly/react-core";
import ReactDatePicker from "react-datepicker";
import { format, parse } from "date-fns";

type BopilCheckboxProps = {
  label?: string;
  id: string;
  name: string;
} & CheckboxProps;

export default function DatePicker({
  label,
  id,
  name,
  ref,
  ...props
}: BopilCheckboxProps) {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const validated = meta.touched && meta.error ? "error" : "default";
  const dateFormat = "dd/MM/yyyy";
  return (
    <FormGroup
      fieldId={id}
      helperTextInvalid={meta.error}
      validated={validated}
      label={label?.replace(/ /g, "\u00a0")}
    >
      <ReactDatePicker
        id="date_deblocage"
        selected={
          field.value ? parse(field.value, dateFormat, new Date()) : new Date()
        }
        locale="fr"
        dateFormat={dateFormat}
        className="pf-c-form__group-control"
        onChange={(date) => {
          if (date !== null) {
            setValue(format(date as Date, dateFormat));
          }
        }}
        customInput={
          <input
            className="pf-c-form-control w-full"
            type="text"
            id={name}
            placeholder={label}
          />
        }
      />
    </FormGroup>
  );
}
